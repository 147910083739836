// employee/components/SalaryHistory.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SalaryHistory as SalaryHistoryType } from '../../types/types';
import styles from '../EmployeeProfile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface SalaryHistoryProps {
  salaryHistory: SalaryHistoryType[] | undefined;
  onEdit: () => void;
}

const SalaryHistory: React.FC<SalaryHistoryProps> = ({ salaryHistory, onEdit }) => {
  const { t } = useTranslation('employee');
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = () => {
    setOpenDropdown(null);
    onEdit();
  };

  return (
    <div className="col-xl col-lg">
      <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h2>{t('salary_history_component.title')}</h2>
          <div className="dropdown no-arrow">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink2"
              onClick={() => toggleDropdown('dropdown2')}
              aria-haspopup="true"
              aria-expanded={openDropdown === 'dropdown2' ? 'true' : 'false'}
            >
              <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
            </a>
            {openDropdown === 'dropdown2' && (
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                aria-labelledby="dropdownMenuLink2"
                onClick={handleMenuItemClick}
              >
                <div className="dropdown-item">
                  {t('salary_history_component.edit')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-body'>
          <div className={styles.tab_content}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>{t('salary_history_component.amount')}</th>
                  <th>{t('salary_history_component.effective_date')}</th>
                </tr>
              </thead>
              <tbody>
                {salaryHistory?.map((salary, index) => (
                  <tr key={index}>
                    <td>${salary.amount.toLocaleString()}</td>
                    <td>{new Date(salary.effectiveDate).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalaryHistory;
