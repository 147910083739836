import { useContext } from "react";
import { useWizard } from "react-use-wizard";
import { StepContext } from "./OnboardingWizard";

const Footer: React.FC = () => {
  const { isFirstStep, isLastStep, previousStep, nextStep, activeStep } = useWizard();
  const { save } = useContext(StepContext);

  const handlePrevious = async () => {
    const success = await save();
    if (success) {
      previousStep();
    } else {
      console.warn("Save failed; staying on the current step.");
    }
  };

  const handleNext = async () => {
    const success = await save();
    if (success) {
      nextStep();
    } else {
      console.warn("Save failed; staying on the current step.");
    }
  };

  return (
    <footer
      style={{
        padding: '1rem',
        borderTop: '1px solid #ccc',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'sticky',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
      }}
    >
      <button
        onClick={handlePrevious}
        disabled={isFirstStep}
        style={{
          padding: '0.5rem 1rem',
          backgroundColor: isFirstStep ? '#ccc' : '#007bff',
          color: isFirstStep ? '#666' : '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: isFirstStep ? 'not-allowed' : 'pointer',
          transition: 'background-color 0.3s ease',
        }}
      >
        Previous
      </button>
      <button
        onClick={handleNext}
        disabled={isLastStep}
        style={{
          padding: '0.5rem 1rem',
          backgroundColor: isLastStep ? '#28a745' : '#007bff',
          color: '#fff',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease',
        }}
      >
        {isLastStep ? 'Submit' : 'Next'}
      </button>
    </footer>
  );
};

export default Footer;
