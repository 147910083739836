import React from 'react';
import { useDispatch } from 'react-redux';
import { Absence, HolidayType } from '../types/absenceTypes';
import styles from './AbsenceList.module.css';

interface AbsenceListProps {
  absences: Absence[];
  absencesTypes: HolidayType[];
  onSelectAbsence: (absence: Absence) => void;
}

const formatDateRange = (startDate: string, endDate: string) => {
  const start = new Date(startDate).toLocaleDateString();
  const end = new Date(endDate).toLocaleDateString();
  return `${start} - ${end}`;
};

const calculateTotalDays = (startDate: string, endDate: string) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const diffTime = Math.abs(end.getTime() - start.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
};

const AbsenceList: React.FC<AbsenceListProps> = ({ absences, absencesTypes, onSelectAbsence }) => {
  const dispatch = useDispatch();

  const getAbsenceTypeName = (typeValue: number) => {
    const type = absencesTypes.find(t => t.value === typeValue);
    return type ? type.name : 'Unknown'; // Fallback to 'Unknown' if no match is found
  };

  // Filter absences: only show from today minus one week onward
  const today = new Date();
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(today.getDate() - 7);

  const filteredAbsences = absences.filter(absence => {
    const absenceStartDate = new Date(absence.startDate);
    return absenceStartDate >= oneWeekAgo;
  }).sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()); // Sort by startDate
  

  // Find the next absence coming up
  const nextAbsence = filteredAbsences.find(absence => {
    const absenceStartDate = new Date(absence.startDate);
    return absenceStartDate >= today;
  });

  return (
    <div className={`${styles.absenceList} ${styles.scrollable}`}>
      <h4>Holidays Booked</h4>
      <ul>
        {filteredAbsences.map(absence => {
          const isNextAbsence = nextAbsence && absence.id === nextAbsence.id;
          return (
            <li
              key={absence.id}
              onClick={() => onSelectAbsence(absence)}
              className={`${isNextAbsence ? styles.nextAbsence : ''} ${styles.absenceItem} `}
            >
              <h5>{getAbsenceTypeName(absence.type as number)}</h5> {/* Lookup name */}
              <div>{formatDateRange(absence.startDate, absence.endDate)}</div>
              <div>{calculateTotalDays(absence.startDate, absence.endDate)} Days</div>
              <div>Status: {absence.status}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AbsenceList;
