import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../store/store';
import { fetchMessages, sendMessage } from '../api/messagesMockApi';
import { MessageState, Thread, Message } from '../types/types';
import { useSelector } from 'react-redux';

const initialState: MessageState = {
  threads: [],
  status: 'idle',
  error: null,
};

// Async thunk to fetch threads
export const fetchThreadsThunk = createAsyncThunk<
  Thread[], // Assuming the response type is an array of threads
  void,     // No arguments passed to the thunk
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'messages/fetchThreads',
  async (_, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    
    // Use the accessToken when calling the API
    const response = await fetchMessages(accessToken); // Assuming this API returns threads with messages
    return response;
  }
);

// Async thunk to send a message
export const sendMessageThunk = createAsyncThunk<
  Message, // Assuming the response is the sent message
  Message, // The argument passed to the thunk is a new message
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'messages/sendMessage',
  async (newMessage, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    
    // Use the accessToken when calling the API
    const response = await sendMessage(accessToken, newMessage);
    return response;
  }
);

// In your messagesSlice.ts
export const updateMessageStatus = createAsyncThunk(
    'messages/updateMessageStatus',
    async ({ messageId, threadId, read }: { messageId: string; threadId: string; read: boolean }) => {
      // Simulate updating the read status
      return { messageId, threadId, read };
    }
  );
  

const messagesSlice = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    addMessageToThread(state, action: PayloadAction<Message>) {
      const { threadId } = action.payload;
      const thread = state.threads.find(thread => thread.id === threadId);
      if (thread) {
        thread.messages.push(action.payload);
        thread.lastUpdated = action.payload.timestamp;
      }
    },
    addThread(state, action: PayloadAction<Thread>) {
      state.threads.push(action.payload);
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchThreadsThunk.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchThreadsThunk.fulfilled, (state, action: PayloadAction<Thread[]>) => {
        state.status = 'succeeded';
        state.threads = action.payload;
      })
      .addCase(fetchThreadsThunk.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch messages';
      })
      .addCase(sendMessageThunk.fulfilled, (state, action: PayloadAction<Message>) => {
        const thread = state.threads.find(thread => thread.id === action.payload.threadId);
        if (thread) {
          thread.messages.push(action.payload);
          thread.lastUpdated = action.payload.timestamp;
        }
      })
      .addCase(updateMessageStatus.fulfilled, (state, action: PayloadAction<{ messageId: string; threadId: string; read: boolean }>) => {
        const { messageId, threadId, read } = action.payload;
        const thread = state.threads.find(thread => thread.id === threadId);
        if (thread) {
          const message = thread.messages.find(message => message.id === messageId);
          if (message) {
            message.read = read;
          }
        }
      });
  },
});

// Selectors
export const selectAllThreads = (state: RootState) => state.messages.threads;
export const selectUnreadThreads = (state: RootState) => state.messages.threads; //TODO!

export const unreadMessagesByThread = createSelector(
  [selectAllThreads],  // Input selector
  (threads) => {
    return threads.reduce((acc, thread) => {
      const unreadMessages = thread.messages
        .filter(message => !message.read && message.sender.id !== '1') // Filtering unread messages
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()); // Sort by timestamp

      if (unreadMessages.length > 0) {
        acc[thread.id] = unreadMessages[0]; // Get the last unread message
      }

      return acc;
    }, {} as Record<string, Message>);
  }
);

export const selectMessageStatus = (state: RootState) => state.messages.status;
export const selectMessageError = (state: RootState) => state.messages.error;

export const { addMessageToThread, addThread } = messagesSlice.actions;

export default messagesSlice.reducer;
