import { useEffect } from 'react';
import { fetchThreadsThunk, selectAllThreads, unreadMessagesByThread } from '../slices/messagesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';

export const useMessageData = () => {
    
    const dispatch = useDispatch<AppDispatch>();
    const threads = useSelector((state: RootState) => selectAllThreads(state));
    const unreadThreads = useSelector((state: RootState) => unreadMessagesByThread(state));
    const status = useSelector((state: RootState) => state.messages.status);
    const error = useSelector((state: RootState) => state.messages.error);


  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchThreadsThunk());
    }
  }, [status, dispatch]);

  return { threads, unreadThreads, status, error }
};
