import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateEmergencyContacts, selectEmergencyContacts } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { ContactInfo } from '../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../components/Common/Carousel';
import styles from './Edit.module.css';
import EditForm from '../../../components/Common/EditForm';

const EditEmergencyContacts: React.FC<{ onClose: () => void, employeeId: string }> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

  const emergencyContacts = useSelector((state: RootState) => selectEmergencyContacts(state, employeeId));
  const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
  const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error); 

  const [newEmergencyContacts, setNewEmergencyContacts] = useState<ContactInfo[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!emergencyContacts || emergencyContacts.length === 0) {
      setNewEmergencyContacts([{ name: '', relationship: '', phoneNumber: '' }]); // Default entry
      setCurrentIndex(0);
    } else {
      setNewEmergencyContacts(emergencyContacts);
    }
  }, [emergencyContacts]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedContacts = [...newEmergencyContacts];
    updatedContacts[index] = { ...updatedContacts[index], [name]: value };
    setNewEmergencyContacts(updatedContacts);
  };

  const handleAdd = () => {
    const updatedContacts = [...newEmergencyContacts, { name: '', relationship: '', phoneNumber: '' }];
    setNewEmergencyContacts(updatedContacts);
    setCurrentIndex(updatedContacts.length - 1);
  };

  const handleRemove = (index: number) => {
    const updatedContacts = newEmergencyContacts.filter((_, i) => i !== index);
    setNewEmergencyContacts(updatedContacts);
    setCurrentIndex((prevIndex) => (prevIndex >= updatedContacts.length ? updatedContacts.length - 1 : prevIndex));
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    dispatch(updateEmergencyContacts({employeeId, newEmergencyContacts}));
    setDropdownOpen(false); // Close the dropdown
    if (!continueEditing) {
      onClose();
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const contactItems = newEmergencyContacts.map((contact: ContactInfo, index: number) => (
    <div key={index} className={`card mb-3 ${styles.card}`}>
      <div className="card-body d-flex justify-content-between align-items-start">
        <div className="w-100" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('name')}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={contact.name}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('relationship')}:</label>
            <input
              type="text"
              className="form-control"
              name="relationship"
              value={contact.relationship}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('phone_number')}:</label>
            <input
              type="text"
              className="form-control"
              name="phoneNumber"
              value={contact.phoneNumber}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => handleRemove(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ));

  return (
    <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <Carousel
        items={contactItems}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        disableNavigation={newEmergencyContacts.length <= 1} // Disable navigation if only one item
      />
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
          <FontAwesomeIcon icon={faPlus} /> {t('add_new')}
        </button>
        <div className="btn-group">
          <button className="btn btn-success" type="submit" disabled={loading}>
            {t('save')}
          </button>
          <button
            className="btn btn-success dropdown-toggle dropdown-toggle-split"
            type="button"
            onClick={toggleDropdown}
            aria-expanded={dropdownOpen}
          ></button>
          <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
            <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
              {t('save_and_continue')}
            </button>
          </div>
        </div>
      </div>
    </EditForm>
  );
};

export default EditEmergencyContacts;
