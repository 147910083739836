export interface UserProfile {
  id: string;
}

export interface UserState {
  profile: UserProfile | null;
  currentUserId: string | null;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  loading: boolean;
  error: string | null;
}

export const createDefaultUserProfile = (id: string = ''): UserProfile => ({
  id
});