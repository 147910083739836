import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import rootReducer from './rootReducer';
import { api } from '../services/api'
import polling from '../services/polling'
import thunk from 'redux-thunk';

import employeeReducer from '../features/employee/slices/employeeDetailsSlice';

import userDetailsReducer from '../features/user/slices/userDetailsSlice'
import dashboardReducer from '../features/dashboard/slices/dashboardSlice';
import alertsReducer from '../features/alerts/slices/alertsSlice'
import messagesReducer from '../features/messages/slices/messagesSlice'
import absenceReducer from '../features/absence/slices/absencesSlice'
import absenceEmployeeReducer from '../features/absence/slices/employeeSlice'; //TODO bundle up reducers by feature to stop collisions


export const store = configureStore({
  reducer: {
    rootReducer,
    [api.reducerPath]: api.reducer,
    employee: employeeReducer,
    user: userDetailsReducer,
    alerts: alertsReducer,
    messages: messagesReducer,
    absences: absenceReducer,
    absencesEmployee: absenceEmployeeReducer,
    dashboard: dashboardReducer,
    polling,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      //TODO test these
      // Ignore these action types
      ignoredActions: ['auth/handleLoginCallback/fulfilled', 'auth/handleSilentLoginCallback/fulfilled', 'auth/setUser'],
      // Ignore these field paths in all actions
      ignoredActionPaths: [],
      // Ignore these paths in the state
      ignoredPaths: ['rootReducer.auth.user'],
    },
  })
  .concat(thunk)
  .concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
