import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import EditForm from '../../../components/Common/EditForm';
import FormInput from '../../../components/Common/FormInput';
import SaveButtonWithDropdown from '../../../components/Common/SaveButtonWithDropdown';
import { updateEmployeeProfile } from '../slices/employeeDetailsSlice';
import { AppDispatch } from '../../../store/store';
import { createDefaultEmployeeProfile, EmployeeProfile } from '../types/types';
import { StepContext } from './OnboardingWizard/OnboardingWizard';
import { useEmployeeData } from '../hooks/useEmployeeData';

const EditPersonalDetails: React.FC<{ onClose?: () => void; employeeId: string }> = ({ onClose, employeeId }) => {
  const { setSave } = useContext(StepContext);
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

  // Retrieve employee profile from Redux state
  const { employeeProfile, loading, error } = useEmployeeData(employeeId);

  // Local state for managing form data
  const [employeeData, setEmployeeData] = useState<EmployeeProfile>(createDefaultEmployeeProfile);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Define the saveStep function
  useEffect(() => {
    const saveStep = async (): Promise<boolean> => {
      try {
        //TODO the status needs to be key'd by the employeeid
        await dispatch(updateEmployeeProfile(employeeData));
        return true; // Indicate success
      } catch (error) {
        console.error("Failed to save employee data:", error);
        return false; // Indicate failure
      }
    };
    setSave(saveStep); // Ensure `setSave` does not change unnecessarily
  }, [setSave, dispatch, employeeData]);

  const hasValue = (str: string | undefined | null): boolean => {
    return str !== undefined && str !== null;
  };

  useEffect(() => {
    if (employeeProfile) {
      const updatedEmployeeData = {
        ...employeeProfile,
        dateOfBirth: employeeProfile?.dateOfBirth?.split('T')[0] || '',
        hireDate: employeeProfile?.hireDate?.split('T')[0] || '',
      };
      setEmployeeData(updatedEmployeeData);
    }
  }, [employeeProfile]);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmployeeData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      await dispatch(updateEmployeeProfile(employeeData));
      setIsSubmitting(false);
      setDropdownOpen(false);
      if (!continueEditing && onClose) {
        onClose();
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <EditForm loading={loading} error={error} onSubmit={handleSubmit}>
      <div className={`card mb-3`}>
        <div className="card-body" style={{ paddingTop: '20px' }}>
          <div className="row">
            <div className="col-md-6">
              <FormInput label={t('full_name')} type="text" name="fullName" value={employeeData.fullName} onChange={handleChange} />
              <FormInput label={t('preferred_name')} type="text" name="preferredName" value={employeeData.preferredName} onChange={handleChange} />
              <FormInput label={t('professional_suffix')} type="text" name="professionalSuffix" value={employeeData.professionalSuffix} onChange={handleChange} />
              <FormInput label={t('payroll_name')} type="text" name="payrollName" value={employeeData.payrollName} onChange={handleChange} />
              <FormInput label={t('tax_id')} type="text" name="taxID" value={employeeData.taxID} onChange={handleChange} />
              <FormInput label={t('date_of_birth')} type="date" name="dateOfBirth" value={employeeData.dateOfBirth} onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <FormInput label={t('hire_date')} type="date" name="hireDate" value={employeeData.hireDate} onChange={handleChange} />
              <FormInput label={t('status')} type="text" name="status" value={employeeData.status} onChange={handleChange} />
              <FormInput label={t('position')} type="text" name="jobTitle" value={employeeData.jobTitle} onChange={handleChange} />
              <FormInput label={t('department')} type="text" name="department" value={employeeData.department} onChange={handleChange} />
              <FormInput label={t('marital_status')} type="text" name="maritalStatus" value={employeeData.maritalStatus} onChange={handleChange} />
              <FormInput label={t('gender')} type="text" name="gender" value={employeeData.gender} onChange={handleChange} />
            </div>
          </div>
        </div>
      </div>

      {onClose && (
        <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
          <SaveButtonWithDropdown
            onSave={handleSubmit}
            loading={loading}
            saveLabel={t('save')}
            saveAndContinueLabel={t('save_and_continue')}
          />
        </div>
      )}
    </EditForm>
  );
};

export default EditPersonalDetails;
