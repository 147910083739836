import React, { useState } from 'react';
import NotFound from '../../../components/Common/NotFound';
import styles from './UserProfile.module.css'; // Assume styles are defined in a CSS module
import Tasks from './Tasks/Tasks';
import Calendar from './Calendar/Calendar';
import Files from './Files/Files';
import EmployeeDetails from './EmployeeDetails/EmployeeDetails';
import ContactInfo from './ContactDetails/ContactDetails';
import Overview from './Overview/Overview'; // Import the new OverviewView component
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import ProfilePicture from '../../../components/Common/ProfilePicture';
import { useEmployeeData } from '../../employee/hooks/useEmployeeData';

const UserProfile: React.FC = () => {

  const userPrinciple = useSelector((state: RootState) => state.rootReducer.auth?.user);
  const userId = userPrinciple?.profile.sub || '';


    const { employeeProfile, status, error } = useEmployeeData(userId);

    const [viewingSection, setViewingSection] = useState<string | null>('overview');

    const renderMainContent = () => {
      switch (viewingSection) {
        case 'overview':
          return (
            <Overview
              profile={employeeProfile!}
            />
          );
        case 'employeeDetails':
          return (
            <EmployeeDetails
              fullName={employeeProfile?.fullName || ''}
              preferredName={employeeProfile?.preferredName || ''}
              professionalSuffix={employeeProfile?.professionalSuffix || ''}
              description={employeeProfile?.description || ''}
              department={employeeProfile?.department || ''}
              jobTitle={employeeProfile?.jobTitle || ''}
              hireDate={employeeProfile?.hireDate || ''}
              manager={employeeProfile?.manager || ''}
              location={employeeProfile?.location || ''}
            />
          );
        case 'contactInfo':
          return (
            <ContactInfo
              email={employeeProfile?.contactDetails?.email || ''}
              phone={employeeProfile?.contactDetails?.phone || ''}
            />
          );
        case 'tasks':
          return <Tasks />;
        case 'calendar':
          return <Calendar />;
        case 'files':
          return <Files />;
        default:
          return null;
      }
    };

    // Utility function to determine if the profilePicture is base64 or URL
    const getProfilePictureSrc = () => {
      if (!employeeProfile || !employeeProfile.profilePicture) return ''; // Return empty string if userProfile or profilePicture is null
      const { profilePicture } = employeeProfile;
      return profilePicture.startsWith('data:image') || profilePicture.startsWith('data:application') || profilePicture.startsWith('data:') 
        ? profilePicture 
        : profilePicture; // Return the URL directly if it's not base64
    };

    if (!employeeProfile) {
      return <NotFound placeholder="User Profile" />;
    }

    return (
      <div className={styles.userProfileContainer}>
        <div className={styles.sidebar}>
        <div className={styles.profileHeader}>
            <ProfilePicture
              src={getProfilePictureSrc()}
              alt={employeeProfile.fullName}
            />
            <h2>{employeeProfile.fullName}</h2>
            <p>{employeeProfile.jobTitle}</p>
          </div>
          <div className={styles.profileNav}>
            <a href="#" onClick={() => setViewingSection('overview')}>Overview</a>
            <a href="#" onClick={() => setViewingSection('employeeDetails')}>Employee Details</a>
            <a href="#" onClick={() => setViewingSection('contactInfo')}>Contact Info</a>
            <a href="#" onClick={() => setViewingSection('tasks')}>Tasks</a>
            <a href="#" onClick={() => setViewingSection('calendar')}>Calendar</a>
            <a href="#" onClick={() => setViewingSection('files')}>Files</a>
          </div>
        </div>

        <div className={styles.mainContent}>
          {renderMainContent()}
        </div>

      </div>
    );
};

export default UserProfile;
