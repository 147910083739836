import { UserProfile } from '../types/types';

export const fetchUserProfile = async (): Promise<UserProfile> => {
  const mockUserProfile: UserProfile = {
    id: 'user123',
    
  };

  return mockUserProfile;
};
