// employee/components/Dependents.tsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ContactInfo } from '../../types/types';
import styles from '../EmployeeProfile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

interface DependentsProps {
  dependents: ContactInfo[] | undefined;
  onEdit: () => void;
}

const Dependents: React.FC<DependentsProps> = ({ dependents, onEdit }) => {
  const { t } = useTranslation('employee');
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const toggleDropdown = (key: string) => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (!(event.target instanceof Element)) return;

    if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const handleMenuItemClick = () => {
    setOpenDropdown(null);
    onEdit();
  };

  return (
    <div className="col-xl col-lg">
      <div className={`card shadow mb-4`} style={{ height: "calc(100% - 23px)" }}>
        <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
          <h2>{t('dependents_component.title')}</h2>
          <div className="dropdown no-arrow">
            <a
              className="dropdown-toggle"
              href="#"
              role="button"
              id="dropdownMenuLink4"
              onClick={() => toggleDropdown('dropdown4')}
              aria-haspopup="true"
              aria-expanded={openDropdown === 'dropdown4' ? 'true' : 'false'}
            >
              <FontAwesomeIcon icon={faEllipsisV} className="text-gray-400" />
            </a>
            {openDropdown === 'dropdown4' && (
              <div
                className="dropdown-menu dropdown-menu-right shadow animated--fade-in show"
                aria-labelledby="dropdownMenuLink4"
                onClick={handleMenuItemClick}
              >
                <div className="dropdown-item">
                  {t('dependents_component.edit')}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-body'>
          <div className={styles.tab_content}>
            {dependents?.map((dependent, index) => (
              <div key={index} className={`${styles.card}`}>
                <p><strong>{t('dependents_component.name')}:</strong> {dependent.name}</p>
                <p><strong>{t('dependents_component.relationship')}:</strong> {dependent.relationship}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dependents;
