import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectWorkExperienceHistory, updateWorkExperience } from '../../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../../store/store';
import { WorkExperienceHistory } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../../components/Common/Carousel';
import styles from '../Edit.module.css';
import EditForm from '../../../../components/Common/EditForm';

const EditWorkExperience: React.FC<{ onClose?: () => void, employeeId: string }> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();
  const workExperienceHistory = useSelector((state: RootState) => selectWorkExperienceHistory(state, employeeId));
  const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
  const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error);

  const [newWorkExperienceHistory, setNewWorkExperienceHistory] = useState<WorkExperienceHistory[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!workExperienceHistory || workExperienceHistory.length === 0) {
      setNewWorkExperienceHistory([{ employer: '', jobTitle: '', startDate: '', endDate: '' }]); // Default entry
      setCurrentIndex(0);
    } else {
      setNewWorkExperienceHistory(workExperienceHistory);
    }
  }, [workExperienceHistory]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedWorkExperienceHistory = [...newWorkExperienceHistory];
    updatedWorkExperienceHistory[index] = { ...updatedWorkExperienceHistory[index], [name]: value };
    setNewWorkExperienceHistory(updatedWorkExperienceHistory);
  };

  const handleAdd = () => {
    const updatedWorkExperienceHistory = [...newWorkExperienceHistory, { employer: '', jobTitle: '', startDate: '', endDate: '' }];
    setNewWorkExperienceHistory(updatedWorkExperienceHistory);
    setCurrentIndex(updatedWorkExperienceHistory.length - 1);
  };

  const handleRemove = (index: number) => {
    const updatedWorkExperienceHistory = newWorkExperienceHistory.filter((_, i) => i !== index);
    setNewWorkExperienceHistory(updatedWorkExperienceHistory);
    setCurrentIndex((prevIndex) => (prevIndex >= updatedWorkExperienceHistory.length ? updatedWorkExperienceHistory.length - 1 : prevIndex));
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    dispatch(updateWorkExperience({employeeId, newWorkExperience: newWorkExperienceHistory}));
    setDropdownOpen(false); // Close the dropdown
    if (!continueEditing) {
      if (onClose !== undefined)
        onClose();
     }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const workExperienceItems = newWorkExperienceHistory?.map((experience: WorkExperienceHistory, index: number) => (
    <div key={index} className={`card mb-3 ${styles.card}`}>
      <div className="card-body d-flex justify-content-between align-items-start">
        <div className="w-100" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('work_experience_history_component.employer')}:</label>
            <input
              type="text"
              className="form-control"
              name="employer"
              value={experience.employer}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('work_experience_history_component.job_title')}:</label>
            <input
              type="text"
              className="form-control"
              name="jobTitle"
              value={experience.jobTitle}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('work_experience_history_component.start_date')}:</label>
            <input
              type="date"
              className="form-control"
              name="startDate"
              value={experience.startDate}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('work_experience_history_component.end_date')}:</label>
            <input
              type="date"
              className="form-control"
              name="endDate"
              value={experience.endDate}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => handleRemove(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ));

  return (
    <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <Carousel
        items={workExperienceItems}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        disableNavigation={newWorkExperienceHistory.length <= 1} // Disable navigation if only one item
      />
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
          <FontAwesomeIcon icon={faPlus} /> {t('work_experience_history_component.add_new')}
        </button>
        {onClose !== undefined && (
          <div className="btn-group">
            <button className="btn btn-success" type="submit" disabled={loading}>
              {t('work_experience_history_component.save')}
            </button>
            <button
              className="btn btn-success dropdown-toggle dropdown-toggle-split"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            ></button>
            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
              <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
                {t('work_experience_history_component.save_and_continue')}
              </button>
            </div>
          </div>
        )}
      </div>
    </EditForm>
  );
};

export default EditWorkExperience;
