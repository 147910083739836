import React from 'react';
import { useWizard } from 'react-use-wizard';

interface HeaderProps {
  stepTitles: string[];
}

const Header: React.FC<HeaderProps> = ({ stepTitles }) => {
  const { activeStep, stepCount } = useWizard();

  // Display step number (1-based index)
  const currentStepLabel = `Step ${activeStep + 1} of ${stepCount}`;
  const title = stepTitles[activeStep] || 'Wizard';

  return (
    <header
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderBottom: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '0.5rem',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <h1 style={{ margin: 0, marginRight: '1rem' }}>{title}</h1>
          <h4 style={{ margin: 0 }}>{currentStepLabel}</h4>
        </div>
      </div>
    </header>
  );
};

export default Header;
