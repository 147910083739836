import axios from 'axios';
import { getAppConfig } from '../../../config/configManager';
import { Employee } from '../types/employeeTypes';

const API_BASE_URL = getAppConfig().baseUrl;

// Helper function to check if the response is successful or throw an error
const handleResponse = (response: any) => {
  if (!response || response.status !== 200) {
    throw new Error(`Failed to fetch data. Status: ${response.status}`);
  }
  return response.data;
};

// Fetch Employees
export const fetchEmployeesApi = async (organisation: string, token: string): Promise<Employee[]> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/employees`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
    return handleResponse(response) as Employee[];
  } catch (error) {
    console.error('Error fetching employees:', error);
    throw new Error('Failed to fetch employees');
  }
};
