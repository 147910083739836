import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';

export const authConfig: UserManagerSettings = {
  authority: 'https://identity.hrharbour.co.uk/',
  client_id: 'HRHarbour_Dev',
  redirect_uri: window.location.origin + '/callback',
  silent_redirect_uri: window.location.origin + '/renew',
  automaticSilentRenew: true,
  response_type: 'code',
  scope: 'openid profile hrh_holiday_service_api hrh_task_service_api hrh_employee_service_api hrh_user_service_api',
  response_mode: "fragment",
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.localStorage, prefix: 'hrharbour' })
};

export const devApiConfig =
{
  baseUrl: 'https://localhost:5001/api'
}
  
export default authConfig;