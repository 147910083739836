import { createDefaultUserProfile, UserProfile } from "../types/types";
import { getAppConfig } from '../../../config/configManager';
import axiosRetry from 'axios-retry';
import axios from "axios";

// Retry failed requests up to 3 times
axiosRetry(axios, { 
   retries: 3,
   retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
   retryCondition(error: any) {
    switch (error.response.status) {
      //retry only if status is 500 or 501
      case 401:
        return false;
      case 409:
        return false;
      default:
        return true;
      }
    },
    onRetry: (retryCount, error, requestConfig) => {
      console.log(`Retry count: `, retryCount);
      if(retryCount > 3) {
        //Todo lets handle failures 
      }
    },
});const API_BASE_URL = getAppConfig().baseUrl;

const createUserProfile = async (organisation: string, token: string, id: string): Promise<UserProfile> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/user/${id}/profile`, createDefaultUserProfile(id), {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    // Axios automatically parses the response as JSON
    return response.data as UserProfile;
  } catch (error) {
    throw new Error('Failed to create new user profile');
  }
};

const fetchUserProfile = async (organisation: string, token: string, id: string): Promise<UserProfile> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/user/${id}/profile`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data as UserProfile;  // Axios automatically parses the JSON
  } catch (error:any) {
    if (axios.isAxiosError(error) && error.response) {
      // Check for ERR:61 in the response
      if (error.status === 409 && error.response.data === 'ERR:61') {
        return createUserProfile(organisation, token, id); // Call createUserProfile if ERR:61
      }
    }
    throw new Error('Failed to fetch user profile');
    }
};

const updateUserProfile = async (organisation: string, token: string, newProfile: UserProfile) => {
  try {
    await axios.put(`${API_BASE_URL}/user/${newProfile.id}/profile`, newProfile, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
  } catch (error) {
    throw new Error('Failed to update user profile');
  }
};

export { fetchUserProfile, updateUserProfile };
