import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { UserManager, User } from 'oidc-client-ts';

import { getAuthConfig } from '../config/configManager';
import Cookies from 'universal-cookie';
import { RootState } from '../store/store';

import { jwtDecode } from "jwt-decode";
import { CustomUser } from '../utils/CustomUser';

interface TokenPayload {
  Organisations?: string[]; // Adjust based on your claim names
}

// Initialize the OpenID Connect client
const userManager = new UserManager(getAuthConfig());
const cookies = new Cookies();

export const userManagerInstance = userManager

// Define the async thunk action for handling the login process
export const login = createAsyncThunk('auth/login', async () => {
  userManager.getUser().then(async (user)=> {
      if (!user)
        {
          await userManager.signinRedirect();
        }

      if (user?.expired)
        {
          await userManager.signinRedirect();
        }
  });
});


export const selectCurrentPrinciple = (state: RootState) => 
  state.rootReducer.auth.user;

// Define the async thunk action for handling the callback
export const handleLoginCallback = createAsyncThunk(
  'auth/handleLoginCallback', 
  async () => {
  const user = await userManager.signinRedirectCallback();
  cookies.remove('hasTriedSignin');
  return user;
});

// Define the async thunk action for handling the callback
export const silentLogin = createAsyncThunk('auth/silentLogin', async () => {
  await userManager.signinSilent();
});

// Define the async thunk action for handling the callback
export const handleSilentLoginCallback = createAsyncThunk('auth/handleSilentLoginCallback', async () => {

  await userManager.signinSilentCallback();
});

// Define the async thunk action for handling the logout process
export const logout = createAsyncThunk('auth/logout', async () => {
  await userManager.signoutRedirect();
});

export const fetchOrganisations = (token: string): string[] => {
  try {
    const base64Url = token.split(".")[1]; // Extract the payload part of the token
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
        .join("")
    );
  
    const parsedToken = JSON.parse(jsonPayload);
  
    // Manually aggregate "Organisations" into an array
    const organisations = Object.entries(parsedToken)
      .filter(([key]) => key === "Organisations")
      .map(([, value]) => value);
  
    //TODO add to the dropdown a remember org and then set the current to its value in the reducers...  
    //cookies.set('currentOrganisation', organisations[0], { path: '/', maxAge: 256000, secure: true, sameSite: 'strict' });

    return organisations as string[]; // Aggregate "Organisations" as an array
    
  } catch (error) {
    console.error("Error decoding token:", error);
    return [];
  }
};

export const fetchName = (token: string): string | null => {
  try {
    const decoded: { [key: string]: any } = jwtDecode(token);
    return decoded.name || null;
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};

// Define the auth slice with initial state
const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null as CustomUser | null,
    loading: false,
    error: null as string | null,
    availableOrganisations: undefined as string[] | undefined,
    currentOrganisation: null as string | null,
  },
  reducers: {
    setUser(state, action: PayloadAction<CustomUser | null>) {
      //We are set at app init this way, however we still have reducers for thunks etc...
      state.user = action.payload;
      state.availableOrganisations = action.payload?.availableOrganisations;

      if (state.availableOrganisations)
      state.currentOrganisation = state.availableOrganisations[0]; 
      state.loading = false;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(handleLoginCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleLoginCallback.fulfilled, (state, action) => {
        state.loading = false;
        const customUser: CustomUser = new CustomUser(action.payload)
        state.availableOrganisations = customUser.availableOrganisations;
        state.currentOrganisation = customUser.currentOrganisation;
        state.user = customUser;
      })
      .addCase(handleLoginCallback.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(handleSilentLoginCallback.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleSilentLoginCallback.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(handleSilentLoginCallback.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? null;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message  ?? null;
      });
  },
});

export const { setUser, setLoading } = authSlice.actions;
export default authSlice.reducer;
