import { User } from 'oidc-client-ts';
import { fetchName, fetchOrganisations } from '../slices/authSlice';

// Define a custom class that extends the User class from oidc-client
export class CustomUser extends User {
    // Add any additional properties specific to your application
    currentOrganisation: string;
    availableOrganisations: string[];
    name: string | null;

    // Constructor to initialize the custom properties
    constructor(
        user: User
    ) {
        // Call the parent class constructor
        super(user);

        const organisations = fetchOrganisations(user.access_token)
        const name = fetchName(user.access_token);

        // Initialize custom properties
        this.name = name;
        this.currentOrganisation = organisations[0];
        this.availableOrganisations = organisations;
    }

    // Example method to check if the user has a specific role
    hasRole(organisation: string): boolean {
        return this.availableOrganisations.includes(organisation);
    }

    // Example method to display user info
    getDisplayInfo(): string {
        return `${this.profile?.name || 'Unknown User'} - ${this.currentOrganisation}`;
    }
}
