// src/api/absenceMockApi.ts
import { Absence, HolidayType, NonWorkingDay } from '../types/absenceTypes';

let mockAbsences: Absence[] = [
  {
    id: 'f5627473-870d-4d08-9780-1c6dfc6ad19e',
    employeeId: 'bc2aaffd-4971-4334-bc71-2a89a6070abe',
    startDate: '2024-08-28',
    endDate: '2024-08-30',
    type: 10,
    status: 10,
  },
  {
    id: '59bfad4f-cfb1-47ed-8659-9ce278eaf884',
    employeeId: 'a515f8d5-21b4-4b15-afca-9913f3e36486',
    startDate: '2024-09-01',
    endDate: '2024-09-02',
    type: 20,
    status: 10,
  },
];

const mockPublicHolidays: NonWorkingDay[] = [
  { date: '2024-12-25', type: 'publicHoliday', description: 'Christmas Day' },
  { date: '2024-12-26', type: 'bankHoliday', description: 'Boxing Day' },
  // Include weekends or other types as necessary
];

const mockAbsenceTypes: HolidayType[] = [
  { name: 'AnnuaLeave', value: 10 },
  // Include weekends or other types as necessary
];

const simulateNetworkDelay = (response: any) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, 500);
  });
};

export const fetchAbsencesApi = async (employeeId: string) => {
  return simulateNetworkDelay(mockAbsences);
};

export const bookAbsenceApi = async (absence: Absence) => {

  // Create a new array with the new absence
  const newAbsences = [...mockAbsences, { ...absence, id: (mockAbsences.length + 1).toString() }];
 
  return simulateNetworkDelay({ data: { ...absence, id: (mockAbsences.length + 1).toString() } });
};

export const fetchNonWorkingDaysApi = async (token: string, employeeId: string) => {
  return mockPublicHolidays;
};

export const fetchAbsenceTypesApi = async (token: string, employeeId: string) => {
  return mockAbsenceTypes;
};
