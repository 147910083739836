import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface ContactInfo {
  name: string;
  relationship: string;
  phoneNumber?: string;
  workPhone?: string;
  personalMobile?: string;
  alternatePhone?: string;
  emailAddress?: string;
  address?: string;
  dateOfBirth?: string;
}

export interface Education {
  degree: string;
  institution: string;
  graduationDate: string;
}

export interface WorkExperienceHistory {
  employer: string;
  jobTitle: string;
  startDate: string;
  endDate?: string;
}

export interface SalaryHistory {
  amount: number;
  effectiveDate: string;
}

export interface SocialLink {
  platform: string;
  url: string;
  icon: IconDefinition;
}

export interface ScheduledLeave {
  type: string;
  startDate: string;
  endDate: string;
}

export interface Project {
  name: string;
  description: string;
}

export interface Equipment {
  name: string;
  details: string;
}

export interface Benefit {
  name: string;
  description: string;
}

export interface Certificate {
  name: string;
  issuedBy: string;
  issueDate: string;
}

export interface ContactDetails {
  phone?: string;
  mobile?: string;
  email?: string;
}

export interface Team {
  id: string;
  Name: string;
}

export interface EmployeeProfile {
  id: string;
  taxID: string;
  fullName: string;
  preferredName: string;
  professionalSuffix: string;
  payrollName: string;
  dateOfBirth: string;
  gender: string;
  maritalStatus: string;
  nationality: string;
  department: string;
  jobTitle: string;
  hireDate: string;
  terminationDate: string | null;
  status: string;
  manager: string;
  emergencyContacts: ContactInfo[];
  nextOfKin: ContactInfo[];
  dependents: ContactInfo[];
  educationHistory: Education[];
  workExperienceHistory: WorkExperienceHistory[];
  salaryHistory: SalaryHistory[];
  terminationReason: string;
  profilePicture: string;
  location: string;
  description: string;
  contactDetails: ContactDetails | null;
  socialLinks: SocialLink[];
  skills: string[];
  knowledgeAreas: string[];
  projects: Project[];
  equipment: Equipment[];
  benefits: Benefit[];
  certificates: Certificate[];
  shoeSize: string;
  tShirtSize: string;
  uniformSize: string;
  primaryAddress: string;
  secondaryAddress: string;
  teams: Team[];
}

export interface EmployeeState {
  profiles: {
    [id: string]: {
      profile: EmployeeProfile | null;
      status: 'idle' | 'loading' | 'succeeded' | 'failed';
      loading: boolean;
      error: string | null;
    };
  };
}

export const createDefaultEmployeeProfile = (id: string = ''): EmployeeProfile => ({
  id,
  taxID: '',
  fullName: '',
  preferredName: '',
  professionalSuffix: '',
  payrollName: '',
  dateOfBirth: '',
  gender: '',
  maritalStatus: '',
  nationality: '',
  department: '',
  jobTitle: '',
  hireDate: '',
  terminationDate: null,
  status: 'draft', // Defaulting to 'active', adjust if needed
  manager: '',
  emergencyContacts: [], // Defaulting to an empty array
  nextOfKin: [],
  dependents: [],
  educationHistory: [],
  workExperienceHistory: [],
  salaryHistory: [],
  terminationReason: '',
  profilePicture: '',
  location: '',
  description: '',
  contactDetails: null, // Assuming null as initial value
  socialLinks: [],
  skills: [],
  knowledgeAreas: [],
  projects: [],
  equipment: [],
  benefits: [],
  certificates: [],
  shoeSize: '',
  tShirtSize: '',
  uniformSize: '',
  primaryAddress: '',
  secondaryAddress: '',
  teams: []
});