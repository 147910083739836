// src/api/employeeMockApi.ts
import { Employee } from '../types/employeeTypes';

const mockEmployees: Employee[] = [
  {
      id: 'ba53064b-7309-40bb-a3b4-370fb3e9189a',
      fullName: 'James Bond',
      preferredName: 'Jim',
      jobTitle: 'Developer',
      department: 'Engineering',
      team: 'Frontend',
      profilePicture: 'https://example.com/profile/alice.jpg',
      professionalSuffix: 'Mr'
  },
  {
    id: 'a515f8d5-21b4-4b15-afca-9913f3e36486',
    fullName: 'Robert Dylan',
    preferredName: 'Bob',
    jobTitle: 'Designer',
    department: 'Design',
    team: 'UI/UX',
    profilePicture: 'https://example.com/profile/bob.jpg',
    professionalSuffix: 'Mr'

  },
  {
    id: 'f3a820b5-dff3-453e-871b-58eed033dc82',
    fullName: 'Charles Ducker',
    preferredName: 'Charlie',
    jobTitle: 'Manager',
    department: 'Management',
    team: 'Operations',
    profilePicture: 'https://example.com/profile/charlie.jpg',
    professionalSuffix: 'Mr'

  },
];

// Update simulateNetworkDelay to accept a generic type T
const simulateNetworkDelay = <T>(response: T): Promise<T> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, 500); // 500ms delay
  });
};

// Fetch Employees
export const fetchEmployeesApi = async (): Promise<Employee[]> => {
  return simulateNetworkDelay<Employee[]>(mockEmployees);
};
