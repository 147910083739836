import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { useLoading } from "../../utils/LoadingContext"; // Import the hook
import styles from "./AppLoading.module.css";

interface AppLoadingProps {
  children?: React.ReactNode; // Optional children
  message?: string; // Optional custom message
}

const TypingText: React.FC = () => {
  const [text, setText] = useState("");
  const [index, setIndex] = useState(0);

  const words = "Welcome to HR Harbour :)";

  useEffect(() => {
    const interval = setInterval(() => {
      if (index < words.length) {
        setText(words.slice(0, index + 1));
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(interval); // Stop the interval once the entire text is displayed
      }
    }, 80);

    return () => clearInterval(interval);
  }, [index]);

  return <div className={styles.typingText}>{text}</div>;
};

export const AppLoading: React.FC<AppLoadingProps> = ({
  children,
}) => {
  const { isLoading, message, isAppInit } = useLoading(); // Consume the shared state

  return (
    <>
      <div className={styles.overlay} style={{ display: isLoading ? 'float' : 'none' }}>
      <div className={styles.container}>
        {(isAppInit) ? <TypingText /> : <></>}
        <TailSpin
          height="80"
          width="80"
          color="#4fa94d"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
        <div>{message}</div> 
      </div>
    </div> 

    <div style={{ display: isLoading ? 'none' : 'block' }}>
      {children}
      </div>

    </>
  );
};

export default AppLoading;
