import React, { useState } from 'react';

interface InviteUserProps {
}

const InviteUser: React.FC<InviteUserProps> = ({ }) => {
  const [email, setEmail] = useState('');

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ marginBottom: '1rem' }}>
        <label htmlFor="email" style={{ display: 'block', marginBottom: '0.5rem' }}>Email Address</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter user's email"
          style={{
            width: '100%',
            padding: '0.5rem',
            border: '1px solid #ccc',
            borderRadius: '4px',
          }}
          required
        />
      </div>
    </div>
  );
};

export default InviteUser;
