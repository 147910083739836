import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, momentLocalizer, View } from 'react-big-calendar';
import moment from 'moment';
import AbsenceBookingForm from './AbsenceBookingForm';
import styles from './CalendarView.module.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AbsenceStatistics from './AbsenceStatistics';
import AbsenceList from './AbsenceList';
import ProfileSummary from './ProfileSummary';
import CustomToolbar from './CustomToolbar';
import { calculateAbsenceStatistics } from './calculateAbsenceStatistics';
import { fetchEmployees } from '../slices/employeeSlice';
import { useAbsenceData } from '../hooks/useAbsenceData';
import { RootState, AppDispatch } from '../../../store/store';
import { Absence } from '../types/absenceTypes';
import { selectCurrentPrinciple } from '../../../slices/authSlice';

const localizer = momentLocalizer(moment);

const CalendarView: React.FC<{ viewOnly?: boolean, teamView?: boolean }> = ({ viewOnly = false, teamView = false }) => {
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchEmployees());
  }, [dispatch]);

  const { employees, loading, error } = useSelector((state: RootState) => state.absencesEmployee);

  const userPrinciple =  useSelector((state: RootState) => selectCurrentPrinciple(state));

  const userId = userPrinciple?.profile.sub || null;

  const { absences, nonWorkingDays, absenceTypes, loading: absencesLoading, error: absencesError } = useAbsenceData(userId || '');
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [selectedRange, setSelectedRange] = useState<{ start: Date | null; end: Date | null }>({ start: null, end: null });

  const [selectedAbsence, setSelectedAbsence] = useState<Absence>({
    id: '',
    employeeId: '',
    description: '',
    startDate: '',
    endDate: '',
    type: 10,
    status: 10,
  });

  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(userId);
  const [contextMenu, setContextMenu] = useState<{ x: number, y: number, show: boolean, booking?: any }>({ x: 0, y: 0, show: false });
  const [calendarDate, setCalendarDate] = useState<Date | null>(new Date());

  const initialAllowance = 25;
  const remainingAllowance = 20;

  const defaultColorPalette = [
    '#4682b4', // Steel Blue
    '#088F8F', // Teal
    '#50c878', // Emerald
    '#FFC300', // Amber
    '#581845', // Plum
    '#FF5733', // Red Orange
    '#C70039', // Crimson
    '#900C3F', // Dark Red
    '#DAF7A6', // Light Green
  ];

  const assignColorsToAbsenceTypes = (types: { name: string; value: number; color?: string }[]) => {
    let paletteIndex = 0;
  
    return types.map(type => {
      if (!type.color) {
        // Assign the next color from the palette, cycling back if needed
        const color = defaultColorPalette[paletteIndex % defaultColorPalette.length];
        paletteIndex++;
        return { ...type, color }; // Assign the color
      }
      return type; // Keep existing color
    });
  };

  const absenceTypesWithColors = assignColorsToAbsenceTypes(absenceTypes);


  if (loading) return <div>Loading employees...</div>;
  if (error) return <div>Error loading employees: {error}</div>;
  if (absencesError) return <div>Error loading absences: {absencesError}</div>;

  const filteredAbsences = selectedEmployee
    ? absences.filter(abs => abs.employeeId === selectedEmployee)
    : absences;

    const absenceEvents = filteredAbsences.map(absence => {
      // Find the matching absence type by value
      const absenceType = absenceTypes.find(type => type.value === absence.type);
    
      return {
        title: absence.description || (absenceType ? absenceType.name : 'Unknown'), // Use the name if found
        start: new Date(absence.startDate),
        end: new Date(new Date(absence.endDate).setDate(new Date(absence.endDate).getDate() + 1)),
        allDay: true,
        resource: absence,
      };
    });

  const nonWorkingDayEvents = nonWorkingDays.map(nonWorkingDay => ({
    title: nonWorkingDay.description || nonWorkingDay.type,
    start: new Date(nonWorkingDay.date),
    end: new Date(nonWorkingDay.date),
    allDay: true,
    resource: nonWorkingDay,
  }));

  const events = [...absenceEvents, ...nonWorkingDayEvents];

  const eventPropGetter = (event: any) => {
    const absenceType = absenceTypesWithColors.find(type => type.value === event.resource.type);
    const backgroundColor = absenceType ? absenceType.color : '#581845';
    return {
      style: {
        backgroundColor,
        color: '#ffffff',
        borderRadius: '8px',
        boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
      },
    };
  };

  const isNonWorkingOrAbsentDay = (date: Date) => {
    return (
      nonWorkingDays.some(nonWorkingDay => {
        const nonWorkingDate = new Date(nonWorkingDay.date);
        nonWorkingDate.setHours(0, 0, 0, 0);
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
        return nonWorkingDate.getTime() === normalizedDate.getTime();
      }) ||
      absences.some(absence => {
        const absenceStart = new Date(absence.startDate);
        const absenceEnd = new Date(absence.endDate);
        absenceStart.setHours(0, 0, 0, 0);
        absenceEnd.setHours(0, 0, 0, 0);
        return date >= absenceStart && date <= absenceEnd;
      })
    );
  };

  const isWeekend = (date: Date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
  };

  const handleSelectSlot = (slotInfo: { start: Date; end: Date }) => {
    if (!viewOnly) {
      let { start, end } = slotInfo;
  
      // Roll forward start if it's a weekend or a non-working/absent day
      while (isWeekend(start) || isNonWorkingOrAbsentDay(start)) {
        start.setDate(start.getDate() + 1);
      }
  
      // Adjust end backward if it includes the next day
      end.setDate(end.getDate() - 1);
  
      // Roll backward end if it's a non-working/absent day
      while (isNonWorkingOrAbsentDay(end)) {
        end.setDate(end.getDate() - 1);
      }
  
      // Roll forward end if it lands on a weekend
      while (isWeekend(end)) {
        end.setDate(end.getDate() + 1);
      }
  
      // Ensure end date is after start date
      if (end < start) {
        end = new Date(start);
      }
  
      setSelectedRange({
        start,
        end,
      });
      setContextMenu({ x: 0, y: 0, show: false });
      setShowBookingForm(true);
    }
  };
  

  const handleSelectEvent = (
    event: any,
    e: React.SyntheticEvent<HTMLElement>
  ) => {
    if (!viewOnly) {
      e.preventDefault();
      const nativeEvent = e.nativeEvent as MouseEvent;
      const x = nativeEvent.clientX;
      const y = nativeEvent.clientY;
      setContextMenu({
        x,
        y,
        show: true,
        booking: event.resource, // Pass the resource object which includes the Id
      });
    }
  };

  const handleContextMenuClose = () => {
    setContextMenu({ x: 0, y: 0, show: false });
  };

  const handleEmployeeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedEmployee(event.target.value);
  };

  const handleSelectAbsence = (absence: Absence) => {
    setSelectedRange({
      start: new Date(absence.startDate),
      end: new Date(absence.endDate),
    });
    setCalendarDate(new Date(absence.startDate));
  };

  const handleNavigate = (newDate: Date, view: View) => {
    setCalendarDate(newDate);
  };

  const selectedEmployeeDetails = employees.find(emp => emp.id === selectedEmployee);

  const { remainingLeave } = calculateAbsenceStatistics(filteredAbsences, initialAllowance);

  return (
    <div className={styles.container}>
      <div className={styles.calendarContainer}>
        {teamView && (<h2>Holidays & Absences</h2>)}
        {!teamView && selectedEmployeeDetails && (
          <ProfileSummary
            name={selectedEmployeeDetails.preferredName || selectedEmployeeDetails.fullName}
            jobTitle={selectedEmployeeDetails.jobTitle}
            department={selectedEmployeeDetails.department}
            team={selectedEmployeeDetails.team}
            profilePicture={selectedEmployeeDetails.profilePicture}
            remainingAllowance={remainingLeave}
          />
        )}

        {teamView && (
          <div className={styles.employeeFilter}>
            <label className={styles.employeeFilterLabel}>
              Select Employee:
              <select
                className={styles.employeeFilterSelect}
                value={selectedEmployee || ''}
                onChange={handleEmployeeChange}
              >
                <option value="">All Employees</option>
                {employees.map(emp => (
                  <option key={emp.id} value={emp.id}>
                    {emp.fullName}
                  </option>
                ))}
              </select>
            </label>
          </div>
        )}

        <Calendar
          date={calendarDate || new Date()}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot}
          onSelectEvent={handleSelectEvent}
          onNavigate={handleNavigate}
          components={{
            toolbar: CustomToolbar,
          }}
          eventPropGetter={eventPropGetter}
        />
      </div>
      <div className={styles.sidebar}>
        <AbsenceList absences={filteredAbsences} absencesTypes={absenceTypes} onSelectAbsence={handleSelectAbsence} />
        <AbsenceStatistics employeeId={selectedEmployee || undefined} teamView={teamView} totalAllowance={initialAllowance} />
        <div className={styles.legend}>
          <h4>Legend</h4>
          {absenceTypesWithColors.map(({ name, color }) => (
            <div key={name} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
              <span
                style={{
                  display: 'inline-block',
                  width: '16px',
                  height: '16px',
                  marginRight: '8px',
                  backgroundColor: color,
                  borderRadius: '50%',
                }}
              ></span>
              {name}
            </div>
          ))}
        </div>
      </div>

      <AbsenceBookingForm
        absenceId={selectedAbsence.id}
        startDate={selectedRange.start}
        endDate={selectedRange.end}
        nonWorkingDays={nonWorkingDays}  
        absences={absences}  
        onClose={() => setShowBookingForm(false)}
        allowance={remainingAllowance} 
        allowPurchaseMoreHolidays={true}
        show={showBookingForm} 
        employeeList={employees}
        absenceTypes={absenceTypes}
      />

{contextMenu.show && (
  <div
    className={styles.contextMenu}
    style={{ top: contextMenu.y, left: contextMenu.x }}
    onClick={handleContextMenuClose}
  >
    <ul>
      {contextMenu.booking?.type === 'publicHoliday' ? (
        <li>Public Holiday</li>
      ) : contextMenu.booking ? (
        <>
          <li
            onClick={() => {
              setShowBookingForm(true);
              setSelectedRange({
                start: new Date(contextMenu.booking.startDate),
                end: new Date(contextMenu.booking.endDate),
              });
              setSelectedAbsence(contextMenu.booking);
              setContextMenu({ ...contextMenu, show: false });
            }}
          >
            Edit
          </li>
          <li>Cancel</li>
        </>
      ) : (
        <li
          onClick={() => {
            setShowBookingForm(true);
            setContextMenu({ ...contextMenu, show: false });
          }}
        >
          Book
        </li>
      )}
    </ul>
  </div>
)}

    </div>
  );
};

export default CalendarView;
