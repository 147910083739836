import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEducationHistory, updateEducationHistory } from '../../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../../store/store';
import { Education } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../../components/Common/Carousel';
import styles from '../Edit.module.css';
import EditForm from '../../../../components/Common/EditForm';

const EditEducationHistory: React.FC<{ onClose: () => void, employeeId: string }> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

    const educationHistory = useSelector((state: RootState) => selectEducationHistory(state, employeeId));
    const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
    const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error); 

    const [newEducationHistory, setNewEducationHistory] = useState<Education[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
      if (!educationHistory || educationHistory.length === 0) {
        setNewEducationHistory([{ degree: '', institution: '', graduationDate: '' }]); // Default entry
        setCurrentIndex(0);
      } else {
        setNewEducationHistory(educationHistory);
      }
    }, [educationHistory]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const { name, value } = e.target;
      const updatedEducationHistory = [...newEducationHistory];
      updatedEducationHistory[index] = { ...updatedEducationHistory[index], [name]: value };
      setNewEducationHistory(updatedEducationHistory);
    };

    const handleAdd = () => {
      const updatedEducationHistory = [...newEducationHistory, { degree: '', institution: '', graduationDate: '' }];
      setNewEducationHistory(updatedEducationHistory);
      setCurrentIndex(updatedEducationHistory.length - 1);
    };

    const handleRemove = (index: number) => {
      const updatedEducationHistory = newEducationHistory.filter((_, i) => i !== index);
      setNewEducationHistory(updatedEducationHistory);
      setCurrentIndex((prevIndex) => (prevIndex >= updatedEducationHistory.length ? updatedEducationHistory.length - 1 : prevIndex));
    };

    const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
      e.preventDefault();
      dispatch(updateEducationHistory({employeeId: '', newEducationHistory}));
      setDropdownOpen(false); // Close the dropdown
      if (!continueEditing) {
        onClose();
      }
    };

    const toggleDropdown = () => {
      setDropdownOpen(!dropdownOpen);
    };

    const educationItems = newEducationHistory?.map((education: Education, index: number) => (
      <div key={index} className={`card mb-3 ${styles.card}`}>
        <div className="card-body d-flex justify-content-between align-items-start">
          <div className="w-100" style={{ paddingTop: '20px' }}>
            <div className="form-group">
              <label>{t('education_history_component.degree')}:</label>
              <input
                type="text"
                className="form-control"
                name="degree"
                value={education.degree}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
            <div className="form-group">
              <label>{t('education_history_component.institution')}:</label>
              <input
                type="text"
                className="form-control"
                name="institution"
                value={education.institution}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
            <div className="form-group">
              <label>{t('education_history_component.graduation_date')}:</label>
              <input
                type="date"
                className="form-control"
                name="graduationDate"
                value={education.graduationDate}
                onChange={(e) => handleChange(e, index)}
              />
            </div>
          </div>
          <button
            type="button"
            className="btn btn-danger ml-2"
            onClick={() => handleRemove(index)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>
    ));

    return (
      <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <Carousel
        items={educationItems}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        disableNavigation={newEducationHistory.length <= 1} // Disable navigation if only one item
      />
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
          <FontAwesomeIcon icon={faPlus} /> {t('education_history_component.add_new')}
        </button>
        <div className="btn-group">
          <button className="btn btn-success" type="submit" disabled={loading}>
            {t('education_history_component.save')}
          </button>
          <button
            className="btn btn-success dropdown-toggle dropdown-toggle-split"
            type="button"
            onClick={toggleDropdown}
            aria-expanded={dropdownOpen}
          ></button>
          <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
            <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
              {t('education_history_component.save_and_continue')}
            </button>
          </div>
        </div>
      </div>
    </EditForm>
  );
};

export default EditEducationHistory;
