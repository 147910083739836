import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../../../store/store';
import { Education, SalaryHistory, EmployeeState, WorkExperienceHistory, ContactDetails, EmployeeProfile, ContactInfo } from '../types/types';
import { Draft } from 'immer';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/employeeMockApi') : require('../api/employeeApi');

interface FetchEmployeeProfileParams {
  employeeId: string;
}

export const selectMyEmployeeProfile = (state: RootState) => 
  state.employee.profiles[state.user.currentUserId || ''];

export const selectEmployeeProfile = createSelector(
  [
    (state: RootState) => state.employee.profiles,
    (_: RootState, employeeId: string) => employeeId,
  ],
  (profiles, employeeId) => {
    const defaultProfile = {
      profile: null,
      status: 'idle',
      loading: false,
      error: null,
    };
    return profiles[employeeId] || defaultProfile;
  }
);

// Selectors based on current employee profile
export const selectEducationHistory = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.educationHistory || []
);

export const selectDependents = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.dependents || []
);

export const selectSalaryHistory = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.salaryHistory || []
);

export const selectWorkExperienceHistory = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.workExperienceHistory || []
);

export const selectEmergencyContacts = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.emergencyContacts || []
);

export const selectPersonalDetails = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employeeProfile) => employeeProfile || null
);

export const selectAddresses = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employeeProfile) => employeeProfile || null
);

export const selectContactDetails = createSelector(
  [(state: RootState, employeeId: string) => selectEmployeeProfile(state, employeeId)],
  (employee) => employee.profile?.contactDetails || null
);


///THUNKS START

function isNullOrEmpty(value: string | null | undefined): boolean {
  return !value || value.trim().length === 0;
}

export const fetchEmployeeProfile = createAsyncThunk<
  { id: string; profile: EmployeeProfile }, // Return both ID and profile
  FetchEmployeeProfileParams,
  {
    rejectValue: string;
    state: RootState; // Access to state
  }
>(
  'employee/fetchEmployeeProfile',
  async ({ employeeId }, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const accessToken = state.rootReducer.auth.user?.access_token ?? '';
      const currentOrganisation = state.rootReducer.auth.currentOrganisation;

      // Use the accessToken when calling the API
      const profile: EmployeeProfile = await api.fetchEmployeeProfile(currentOrganisation, accessToken, employeeId);
      //
      //TODO dodgy hack but if empty use the users preferred name, maybe do it in the reducer? Need to ensure it only applies to the logged in user
      if (isNullOrEmpty(profile.preferredName) && isNullOrEmpty(profile.fullName) && employeeId === state.rootReducer.auth.user?.profile.sub)
      {
        profile.preferredName = state.rootReducer.auth.user.name || '' ;
        profile.fullName = state.rootReducer.auth.user.name || '' ;
      }

      return { id: employeeId, profile };
    } catch (error) {
      return rejectWithValue('Failed to fetch employee profile');
    }
  }
);

export const updateEmployeeProfile = createAsyncThunk<
  EmployeeProfile,
  EmployeeProfile,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEmployeeProfile',
  async (newProfile, { dispatch, getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    await api.updateEmployeeProfile(currentOrganisation, accessToken, newProfile);
    return newProfile;
  }
);

export const updateContactDetails = createAsyncThunk<
  ContactDetails | null,
  { employeeId: string; newContactDetails: ContactDetails | null },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateContactDetails',
  async ({ employeeId, newContactDetails }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile from the state
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      contactDetails: newContactDetails,
    };

    // Dispatch the update action with the updated profile
    await dispatch(updateEmployeeProfile(updatedProfile));

    return newContactDetails;
  }
);

export const updateEducationHistory = createAsyncThunk<
  Education[],
  { employeeId: string; newEducationHistory: Education[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEducationHistory',
  async ({ employeeId, newEducationHistory }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      educationHistory: newEducationHistory,
    };

    await dispatch(updateEmployeeProfile(updatedProfile));
    return newEducationHistory;
  }
);


export const updateDependents = createAsyncThunk<
  ContactInfo[],
  { employeeId: string; newDependents: ContactInfo[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateDependents',
  async ({ employeeId, newDependents }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      dependents: newDependents,
    };

    await dispatch(updateEmployeeProfile(updatedProfile));
    return newDependents;
  }
);


export const updateSalaryHistory = createAsyncThunk<
  SalaryHistory[],
  { employeeId: string; newSalaryHistory: SalaryHistory[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateSalaryHistory',
  async ({ employeeId, newSalaryHistory }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      salaryHistory: newSalaryHistory,
    };

    await dispatch(updateEmployeeProfile(updatedProfile));
    return newSalaryHistory;
  }
);


export const updateWorkExperience = createAsyncThunk<
  WorkExperienceHistory[],
  { employeeId: string; newWorkExperience: WorkExperienceHistory[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateWorkExperience',
  async ({ employeeId, newWorkExperience }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      workExperienceHistory: newWorkExperience,
    };

    await dispatch(updateEmployeeProfile(updatedProfile));
    return newWorkExperience;
  }
);


export const updateEmergencyContacts = createAsyncThunk<
  ContactInfo[],
  { employeeId: string; newEmergencyContacts: ContactInfo[] },
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  'employee/updateEmergencyContacts',
  async ({ employeeId, newEmergencyContacts }, { dispatch, getState }) => {
    const state = getState();

    // Access only the EmployeeProfile
    const existingProfile = state.employee.profiles[employeeId]?.profile;

    if (!existingProfile) {
      throw new Error(`Employee profile with ID ${employeeId} not found.`);
    }

    const updatedProfile: EmployeeProfile = {
      ...existingProfile,
      emergencyContacts: newEmergencyContacts,
    };

    await dispatch(updateEmployeeProfile(updatedProfile));
    return newEmergencyContacts;
  }
);


///THUNKS END

const initialState: EmployeeState = {
  profiles: {},
};

const employeeDetailsSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchEmployeeProfile.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (!state.profiles[employeeId]) {
        state.profiles[employeeId] = {
          profile: null,
          status: 'idle',
          loading: false,
          error: null,
        };
      }
      state.profiles[employeeId].status = 'loading';
      state.profiles[employeeId].loading = true;
      state.profiles[employeeId].error = null;
    })
    .addCase(fetchEmployeeProfile.fulfilled, (state, action) => {
      const { id, profile } = action.payload;
      state.profiles[id] = {
        profile,
        status: 'succeeded',
        loading: false,
        error: null,
      };
    })
    .addCase(fetchEmployeeProfile.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
    
      // Ensure the employeeId entry exists
      if (!state.profiles[employeeId]) {
        state.profiles[employeeId] = {
          profile: null,
          status: 'idle',
          loading: false,
          error: null,
        };
      }
    
      state.profiles[employeeId].status = 'failed';
      state.profiles[employeeId].loading = false;
      state.profiles[employeeId].error = action.error.message || 'Failed to fetch';
    }).addCase(updateEmployeeProfile.pending, (state, action) => {
      const employeeId = action.meta.arg.id;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateEmployeeProfile.fulfilled, (state, action) => {
      const employeeId = action.payload.id;
      state.profiles[employeeId] = {
        ...state.profiles[employeeId],
        profile: action.payload,
        loading: false,
      };
    })
    .addCase(updateEmployeeProfile.rejected, (state, action) => {
      const employeeId = action.meta.arg.id;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update employee profile';
      }
    })
    //TODO
    .addCase(updateContactDetails.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateContactDetails.fulfilled, (state, action) => {
      updateEmployeeProfileField(state, action.meta.arg.employeeId, 'contactDetails', action.payload);
    })
    .addCase(updateContactDetails.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update contact details';
      }
    })
    .addCase(updateEducationHistory.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateEducationHistory.fulfilled, (state, action) => {
      updateEmployeeProfileField(state, action.meta.arg.employeeId, 'educationHistory', action.payload);
    })
    .addCase(updateEducationHistory.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update education history';
      }
    })
    .addCase(updateSalaryHistory.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateSalaryHistory.fulfilled, (state, action) => {
      updateEmployeeProfileField(state, action.meta.arg.employeeId, 'salaryHistory', action.payload);
    })
    .addCase(updateSalaryHistory.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update salary history';
      }
    })
    .addCase(updateWorkExperience.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateWorkExperience.fulfilled, (state, action) => {
      updateEmployeeProfileField(state, action.meta.arg.employeeId, 'workExperienceHistory', action.payload);
    })
    .addCase(updateWorkExperience.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update work experience';
      }
    })
    .addCase(updateEmergencyContacts.pending, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = true;
        state.profiles[employeeId].error = null;
      }
    })
    .addCase(updateEmergencyContacts.fulfilled, (state, action) => {
      updateEmployeeProfileField(state, action.meta.arg.employeeId, 'emergencyContacts', action.payload);
    })
    .addCase(updateEmergencyContacts.rejected, (state, action) => {
      const employeeId = action.meta.arg.employeeId;
      if (state.profiles[employeeId]) {
        state.profiles[employeeId].loading = false;
        state.profiles[employeeId].error = action.error.message || 'Failed to update emergency contacts';
      }
    })
      ;
  },
});

function updateEmployeeProfileField<T extends keyof EmployeeProfile>(
  state: Draft<EmployeeState>,
  employeeId: string,
  field: T,
  value: EmployeeProfile[T]
) {
  const existingProfile = state.profiles[employeeId]?.profile;

  if (existingProfile) {
    state.profiles[employeeId].profile = {
      ...existingProfile,
      [field]: value,
    } as EmployeeProfile;
    state.profiles[employeeId].loading = false;
  }
}

export default employeeDetailsSlice.reducer;
