import React from 'react';

interface ReviewAndSubmitProps {
  data: any; // Replace with the actual data type of the employee profile
  onSubmit?: () => void;
}

const ReviewAndSubmit: React.FC<ReviewAndSubmitProps> = ({ data, onSubmit }) => {
  const handleSubmit = () => {
    if (onSubmit) onSubmit();
    console.log('Form submitted:', data);
  };

  return (
    <div style={{ padding: '1rem' }}>
      <div style={{ marginBottom: '1rem' }}>
        <h4>Personal Details</h4>
        <p><strong>Full Name:</strong> {data?.fullName || 'N/A'}</p>
        <p><strong>Date of Birth:</strong> {data?.dateOfBirth || 'N/A'}</p>
        <p><strong>Email:</strong> {data?.email || 'N/A'}</p>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <h4>Addresses</h4>
        <p><strong>Primary Address:</strong> {data?.primaryAddress || 'N/A'}</p>
        <p><strong>Secondary Address:</strong> {data?.secondaryAddress || 'N/A'}</p>
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <h4>Work Experience</h4>
        {data?.workExperience?.map((experience: any, index: number) => (
          <div key={index}>
            <p><strong>Company:</strong> {experience.company}</p>
            <p><strong>Role:</strong> {experience.role}</p>
            <p><strong>Years:</strong> {experience.years}</p>
          </div>
        )) || <p>No work experience provided.</p>}
      </div>
    </div>
  );
};

export default ReviewAndSubmit;
