import { MenuItem } from './types/types';
import MenuList from './components/MenuList';

interface SidebarProps {
  routes: MenuItem[];
  isPinned: boolean;
}

const Sidebar: React.FC<React.PropsWithChildren<SidebarProps>> = ({ routes, isPinned, children }) => {
  
  return (
    <div className='view-container'>

    <MenuList menus={routes} />
    <div style={{width: "100%"}}>{children}</div>
    </div>
  );
};

export default Sidebar;
