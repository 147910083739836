import axios from 'axios';
import { getAppConfig } from '../../../config/configManager';

const API_BASE_URL = getAppConfig().baseUrl;

const fetchAnnualLeaveStats = async (organisation: string, id: string, token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/Employee/${id}/Statistics`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data;  // Axios automatically parses the response as JSON
  } catch (error) {
    throw new Error('Failed to fetch annual leave stats');
  }
};

const fetchRequestsAndTasks = async (organisation: string, id: string, token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tasks/Employee/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch requests and tasks');
  }
};

const fetchDaysSinceLastHoliday = async (organisation: string, id: string, token: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/Employee/${id}/Statistics/DaysSinceLastHoliday`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch days since last holiday');
  }
};

export { fetchAnnualLeaveStats, fetchRequestsAndTasks, fetchDaysSinceLastHoliday };
