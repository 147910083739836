import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDependents, updateDependents } from '../../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../../store/store';
import { ContactInfo } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../../components/Common/Carousel';
import styles from '../Edit.module.css';
import EditForm from '../../../../components/Common/EditForm';

interface EditDependentsProps {
  onClose: () => void;
  employeeId: string;
}

const EditDependents: React.FC<EditDependentsProps> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();

  const dependents = useSelector((state: RootState) => selectDependents(state, employeeId));
  const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
  const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error); 

  const [newDependents, setNewDependents] = useState<ContactInfo[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!dependents || dependents.length === 0) {
      setNewDependents([{ name: '', relationship: '' }]); // Default entry
      setCurrentIndex(0);
    } else {
      setNewDependents(dependents);
    }
  }, [dependents]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedDependents = [...newDependents];
    updatedDependents[index] = { ...updatedDependents[index], [name]: value };
    setNewDependents(updatedDependents);
  };

  const handleAdd = () => {
    const updatedDependents = [...newDependents, { name: '', relationship: '' }];
    setNewDependents(updatedDependents);
    setCurrentIndex(updatedDependents.length - 1);
  };

  const handleRemove = (index: number) => {
    const updatedDependents = newDependents.filter((_, i) => i !== index);
    setNewDependents(updatedDependents);
    setCurrentIndex((prevIndex) => (prevIndex >= updatedDependents.length ? updatedDependents.length - 1 : prevIndex));
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    dispatch(updateDependents({employeeId, newDependents}));
    setDropdownOpen(false); // Close the dropdown
    if (!continueEditing) {
      onClose();
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dependentItems = newDependents.map((dependent: ContactInfo, index: number) => (
    <div key={index} className={`card mb-3 ${styles.card}`}>
      <div className="card-body d-flex justify-content-between align-items-start">
        <div className="w-100" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('dependents_component.name')}:</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={dependent.name}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('dependents_component.relationship')}:</label>
            <input
              type="text"
              className="form-control"
              name="relationship"
              value={dependent.relationship}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => handleRemove(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ));

  return (
    <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <div>
        <Carousel
          items={dependentItems}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          disableNavigation={newDependents.length <= 1}
        />
        <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
          <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
            <FontAwesomeIcon icon={faPlus} /> {t('dependents_component.add_new')}
          </button>
          <div className="btn-group">
            <button className="btn btn-success" type="submit" disabled={loading}>
              {t('dependents_component.save')}
            </button>
            <button
              className="btn btn-success dropdown-toggle dropdown-toggle-split"
              type="button"
              onClick={toggleDropdown}
              aria-expanded={dropdownOpen}
            ></button>
            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
              <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
                {t('dependents_component.save_and_continue')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </EditForm>
  );
};

export default EditDependents;