import React, { useState, useEffect, useRef, createContext } from 'react';
import { Wizard, useWizard } from 'react-use-wizard';
import StepWrapper from './StepWrapper';
import EditPersonalDetails from '../EditPersonalDetails';
import EditAddresses from '../EditAddresses';
import EditWorkExperience from '../WorkExperience/EditWorkExperience';
import InviteUser from './InviteUser';
import ReviewAndSubmit from './ReviewAndSubmit';
import Header from './Header';
import Footer from './Footer';
import { useEmployeeData } from '../../hooks/useEmployeeData';
import { useParams } from 'react-router-dom';

const stepTitles = [
  'Personal Details',
  'Addresses',
  'Work Experience',
  'Invite User',
  'Review & Submit'
];

export const StepContext = createContext({
  save: async (): Promise<boolean> => false,
  setSave: (_fn: () => Promise<boolean>) => {}, // Setter for save function
});

export const StepProvider = ({ children }: { children: React.ReactNode }) => {
  const saveRef = useRef<() => Promise<boolean>>(async () => false);

  const setSave = (fn: () => Promise<boolean>) => {
    saveRef.current = fn; // Update the ref with the new save function
  };

  const save = async (): Promise<boolean> => {
    return await saveRef.current(); // Call the current save function
  };

  return (
    <StepContext.Provider value={{ save, setSave }}>
      {children}
    </StepContext.Provider>
  );
};

const OnboardingWizard: React.FC = () => {

  const { newEmployeeId } = useParams<{ newEmployeeId: string }>();
  const employeeIdRef = useRef(newEmployeeId);
  const { employeeProfile, status, error } = useEmployeeData(employeeIdRef.current || '');
  
  return (

    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', paddingLeft: '100px', paddingRight: '100px' }}>
    <StepProvider>
      <Wizard
        startIndex={0}
        header={<Header stepTitles={stepTitles}/>}
        footer={<Footer/>}
      >
        <EditPersonalDetails employeeId={employeeIdRef.current || ''} />
        <EditAddresses employeeId={employeeIdRef.current || ''} />
        <EditWorkExperience employeeId={employeeIdRef.current || ''} />
        <InviteUser />
        <ReviewAndSubmit data={employeeProfile} />
      </Wizard>
      </StepProvider>
    </div>
  );
};

export default OnboardingWizard;
