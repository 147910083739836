import { User } from 'oidc-client-ts';
import React from 'react';

export const AuthContext = React.createContext<{
  user: User | null;
  loading: boolean;
  login: () => void;
  logout: () => void;
}>({
  user: null,
  loading: false,
  login: () => {},
  logout: () => {},
});