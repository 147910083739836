import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactDetails, selectContactDetails } from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { ContactDetails } from '../types/types';
import { useTranslation } from 'react-i18next';
import styles from './Edit.module.css';
import EditForm from '../../../components/Common/EditForm';

const EditContactInfo: React.FC<{ onClose: () => void, employeeId: string }> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();
  const contactDetails = useSelector((state: RootState) => selectContactDetails(state, employeeId));
  const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
  const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error); 

  const [newContactDetails, setNewContactDetails] = useState<ContactDetails | null>(contactDetails);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    setNewContactDetails(contactDetails);
  }, [contactDetails]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (newContactDetails) {
      setNewContactDetails({ ...newContactDetails, [name]: value });
    }
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    if (newContactDetails) {
      dispatch(updateContactDetails({employeeId, newContactDetails}));
      setDropdownOpen(false);  // Close the dropdown
      if (!continueEditing) {
        onClose();
      }
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <div className={`card mb-3 ${styles.card}`}>
        <div className="card-body" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('contact_info.phone')}:</label>
            <input
              type="text"
              className="form-control"
              name="phone"
              value={newContactDetails?.phone || ''}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label>{t('contact_info.email')}:</label>
            <input
              type="text"
              className="form-control"
              name="email"
              value={newContactDetails?.email || ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <div className="btn-group">
          <button className="btn btn-success" type="submit" disabled={loading}>
            {t('contact_info.save')}
          </button>
          <button
            className="btn btn-success dropdown-toggle dropdown-toggle-split"
            type="button"
            onClick={toggleDropdown}
            aria-expanded={dropdownOpen}
          ></button>
          <div
            className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}
            style={{ display: dropdownOpen ? 'block' : 'none' }}
          >
            <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
              {t('contact_info.save_and_continue')}
            </button>
          </div>
        </div>
      </div>
    </EditForm>
  );
};

export default EditContactInfo;
