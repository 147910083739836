import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { UserProfile } from '../../types/types';
import styles from './Overview.module.css';
import { EmployeeProfile } from '../../../employee/types/types';

interface OverviewProps {
  profile: EmployeeProfile;
}

const Overview: React.FC<OverviewProps> = ({ profile }) => {
  return (
    <>
      <section className={`mb-4 p-3 ${styles.section}`}>
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="mb-0">Overview</h3>
        </div>
      </section>

      <section className={`mb-4 p-3 card ${styles.section}`}>
        <h4>{profile.preferredName} spends most of their time on...</h4>
        <ul className="list-unstyled mt-3">
          {/* {profile.focusAreas.map((area, index) => (
            <li key={index} className="mb-2">
              <span className="badge bg-primary">{area}</span>
            </li>
          ))} */}
        </ul>
      </section>

      <section className={`mb-4 p-3 card ${styles.section}`}>
        <h4>Works most with...</h4>
        <div className="d-flex flex-wrap mt-3">
          {/* {profile.collaborators.map((collaborator, index) => (
            <div key={index} className="me-3 mb-2 text-center">
              <img
                src={collaborator.avatarUrl}
                alt={collaborator.name}
                className={`rounded-circle ${styles.avatar}`}
              />
              <small className="d-block mt-1">{collaborator.name}</small>
            </div>
          ))} */}
        </div>
      </section>

      <section className={`mb-4 p-3 card ${styles.section}`}>
        <h4>In these teams...</h4>
        <div className="d-flex flex-wrap mt-3">
          {/* {profile.teams.map((team, index) => (
            <span key={index} className="badge bg-secondary me-2 mb-2">
              {team}
            </span>
          ))} */}
        </div>
      </section>
    </>
  );
};

export default Overview;
