import React, { createContext, useContext, useState } from "react";

interface LoadingContextProps {
  isAppInit: boolean;
  setIsAppInit: (loading: boolean) => void;
  isLoading: boolean;
  setIsLoading: (loading: boolean) => void;
  message: string;
  setMessage: (message: string) => void;
}


const LoadingContext = createContext<LoadingContextProps | undefined>(undefined);

export const LoadingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAppInit, setIsAppInit] = useState(true);

  const [message, setMessage] = useState("Loading...");
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading, message, setMessage, isAppInit, setIsAppInit }}>
      {children}
    </LoadingContext.Provider>
  );
};

// Hook for consuming the loading context
export const useLoading = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
