import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import styles from './ContactInfoView.module.css';

interface ContactProps {
  email: string;
  phone: string;
}

const Contact: React.FC<ContactProps> = ({ email, phone }) => {
  return (
    <>
      <section className={`mb-4 p-3 ${styles.section}`}>
      <div className="d-flex justify-content-between align-items-center">
        <h3 className="mb-0">Contact Info</h3>
      </div>
      </section>
    <section className={`mb-4 p-3 card ${styles.section}`}>
      <div className="text-secondary">
        <p className="mb-2">
          <strong>Email:</strong> {email}
        </p>
        <p>
          <strong>Phone:</strong> {phone}
        </p>
      </div>
    </section>
    </>
  );
};

export default Contact;
