import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSalaryHistory, updateSalaryHistory } from '../../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../../store/store';
import { SalaryHistory } from '../../types/types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../../../components/Common/Carousel';
import styles from '../Edit.module.css';
import EditForm from '../../../../components/Common/EditForm';

const EditSalaryHistory: React.FC<{ onClose: () => void, employeeId: string }> = ({ onClose, employeeId }) => {
  const { t } = useTranslation('employee');
  const dispatch: AppDispatch = useDispatch();
  const salaryHistory = useSelector((state: RootState) => selectSalaryHistory(state, employeeId));
  const loading = useSelector((state: RootState) => state.employee.profiles[employeeId].loading);
  const error = useSelector((state: RootState) => state.employee.profiles[employeeId].error); 

  const [newSalaryHistory, setNewSalaryHistory] = useState<SalaryHistory[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  useEffect(() => {
    if (!salaryHistory || salaryHistory.length === 0) {
      setNewSalaryHistory([{ amount: 0, effectiveDate: '' }]); // Default entry
      setCurrentIndex(0);
    } else {
      setNewSalaryHistory(salaryHistory);
    }
  }, [salaryHistory]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = e.target;
    const updatedSalaryHistory = [...newSalaryHistory];
    updatedSalaryHistory[index] = { ...updatedSalaryHistory[index], [name]: value };
    setNewSalaryHistory(updatedSalaryHistory);
  };

  const handleAdd = () => {
    const updatedSalaryHistory = [...newSalaryHistory, { amount: 0, effectiveDate: '' }];
    setNewSalaryHistory(updatedSalaryHistory);
    setCurrentIndex(updatedSalaryHistory.length - 1);
  };

  const handleRemove = (index: number) => {
    const updatedSalaryHistory = newSalaryHistory.filter((_, i) => i !== index);
    setNewSalaryHistory(updatedSalaryHistory);
    setCurrentIndex((prevIndex) => (prevIndex >= updatedSalaryHistory.length ? updatedSalaryHistory.length - 1 : prevIndex));
  };

  const handleSubmit = (e: React.FormEvent, continueEditing = false) => {
    e.preventDefault();
    dispatch(updateSalaryHistory({employeeId, newSalaryHistory}));
    setDropdownOpen(false); // Close the dropdown
    if (!continueEditing) {
      onClose();
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const salaryItems = newSalaryHistory?.map((salary: SalaryHistory, index: number) => (
    <div key={index} className={`card mb-3 ${styles.card}`}>
      <div className="card-body d-flex justify-content-between align-items-start">
        <div className="w-100" style={{ paddingTop: '20px' }}>
          <div className="form-group">
            <label>{t('salary_history_component.amount')}:</label>
            <input
              type="number"
              className="form-control"
              name="amount"
              value={salary.amount}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
          <div className="form-group">
            <label>{t('salary_history_component.effective_date')}:</label>
            <input
              type="date"
              className="form-control"
              name="effectiveDate"
              value={salary.effectiveDate}
              onChange={(e) => handleChange(e, index)}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-danger ml-2"
          onClick={() => handleRemove(index)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  ));

  return (
    <EditForm loading={loading} error={error} onSubmit={(e) => handleSubmit(e)}>
      <Carousel
        items={salaryItems}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        disableNavigation={newSalaryHistory.length <= 1} // Disable navigation if only one item
      />
      <div className="d-flex justify-content-end mb-3" style={{ paddingRight: '30px' }}>
        <button type="button" className="btn btn-primary mr-2" onClick={handleAdd}>
          <FontAwesomeIcon icon={faPlus} /> {t('salary_history_component.add_new')}
        </button>
        <div className="btn-group">
          <button className="btn btn-success" type="submit" disabled={loading}>
            {t('salary_history_component.save')}
          </button>
          <button
            className="btn btn-success dropdown-toggle dropdown-toggle-split"
            type="button"
            onClick={toggleDropdown}
            aria-expanded={dropdownOpen}
          ></button>
          <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`} style={{ display: dropdownOpen ? 'block' : 'none' }}>
            <button className="dropdown-item" type="button" onClick={(e) => handleSubmit(e, true)}>
              {t('salary_history_component.save_and_continue')}
            </button>
          </div>
        </div>
      </div>
    </EditForm>
  );
};

export default EditSalaryHistory;
