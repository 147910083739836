import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  fetchEmployeeProfile,
  updateEmployeeProfile,
  selectEmployeeProfile,
} from '../slices/employeeDetailsSlice';
import { RootState, AppDispatch } from '../../../store/store';
import { EmployeeProfile } from '../types/types';

export const useEmployeeData = (employeeId: string) => {
  const dispatch = useDispatch<AppDispatch>();

  // Selectors //TODO memomise
  const { profile, status, error } = useSelector((state: RootState) =>
    selectEmployeeProfile(state, employeeId)
  );
  // Local state
  const [localProfile, setLocalProfile] = useState<EmployeeProfile | null>(profile);
  const [saving, setSaving] = useState(false);

  // Fetch profile if needed
  useEffect(() => {
    if (employeeId && (status === 'idle' || !profile)) {
      dispatch(fetchEmployeeProfile({ employeeId }));
    }
  }, [employeeId, status, profile, dispatch]);

  // Sync local state with profile from the store
  useEffect(() => {
    setLocalProfile(profile);
  }, [profile]);

  // Handle changes to the profile
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLocalProfile(prev => (prev ? { ...prev, [name]: value } : null));
  };

  // Handle form submission
  const handleSubmit = async (
    e: React.FormEvent,
    continueEditing: boolean,
    onClose: () => void,
    setDropdownOpen: (open: boolean) => void
  ) => {
    e.preventDefault();
    if (!localProfile) return;

    setSaving(true);
    try {
      await dispatch(updateEmployeeProfile(localProfile));
    } finally {
      setSaving(false);
      setDropdownOpen(false);
      if (!continueEditing) onClose();
    }
  };

  return {
    employeeProfile: localProfile,
    status,
    error,
    handleChange,
    handleSubmit,
    loading: status === 'loading' || saving,
  };
};
