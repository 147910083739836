import React from 'react';
import styles from './EmployeeDetailsView.module.css';

interface EmployeeDetailsProps {
  fullName: string;
  preferredName: string;
  professionalSuffix: string;
  description: string;
  department: string;
  jobTitle: string;
  hireDate: string;
  manager: string;
  location: string;
}

const EmployeeDetails: React.FC<EmployeeDetailsProps> = ({
  fullName,
  preferredName,
  professionalSuffix,
  description,
  department,
  jobTitle,
  hireDate,
  manager,
  location,
}) => {
  return (
    <>
      <section className={`mb-4 p-3 ${styles.section}`}>
      <div className="d-flex justify-content-between align-items-center">
      <h3 className="mb-0">Employee Details</h3>
    </div>
    </section>
    <section className={`mb-4 p-4 card ${styles.section}`}>
      <div className="text-secondary">
        <p><strong>Full Name:</strong> {fullName}</p>
        <p><strong>Preferred Name:</strong> {preferredName}</p>
        <p><strong>Professional Suffix:</strong> {professionalSuffix}</p>
        <p><strong>Description:</strong> {description}</p>
        <p><strong>Department:</strong> {department}</p>
        <p><strong>Job Title:</strong> {jobTitle}</p>
        <p><strong>Hire Date:</strong> {hireDate}</p>
        <p><strong>Manager:</strong> {manager}</p>
        <p><strong>Location:</strong> {location}</p>
      </div>
    </section>
    </>
  );
};

export default EmployeeDetails;
