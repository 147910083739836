import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { TailSpin } from "react-loader-spinner";
import styles from "./AutoLogin.module.css"; // Import the CSS file for styling
import { login } from '../slices/authSlice';

import Cookies from 'universal-cookie';
import { User } from 'oidc-client-ts';
import { useLoading } from './LoadingContext';

interface AutoLoginProps {
  user: User | null;
}

export const AutoLogin: React.FC<AutoLoginProps> = (props) => {

  const cookies = new Cookies();
  const { setIsLoading } = useLoading();
  
  setIsLoading(true);

  const handleLogin = () => {
    dispatch(login());
  };

  const SetCookie = () => 
  {
    cookies.set('hasTriedSignin', 'true', { path: '/', maxAge: 15, secure: true, sameSite: 'strict' });
  };

  const dispatch = useDispatch<AppDispatch>();
  var hasTried = cookies.get('hasTriedSignin');
   // automatically sign-in

  useEffect(() => {
    if (props.user === null && !hasTried) {
      setTimeout(() => {
        SetCookie();
        handleLogin();
      }, 3000);
    }
    setTimeout(() => {
      if (props.user === null && !hasTried) {
        SetCookie();
        handleLogin();
      }
    }, 7250);
  }, [dispatch, SetCookie, handleLogin, hasTried, props.user]);


  return (
    <>Loading behind the scenes....</>
   
  );
};
