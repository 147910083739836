// src/slices/absencesSlice.ts
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { Absence, HolidayType, initialState, NonWorkingDay } from '../types/absenceTypes';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/absenceMockApi') : require('../api/absenceApi');

// Fetch Absences
export const fetchAbsences = createAsyncThunk<
  Absence[], // Adjust the response type accordingly
  string,    // Argument passed to the thunk (the dynamic parameter)
  {
    state: RootState; // Access to the state
  }
>(
  'absences/fetchAbsences',
  async (employeeId, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    // Use the accessToken and absenceId when calling the API
    const data = await api.fetchAbsencesApi(currentOrganisation, accessToken, employeeId);
    return data;
  }
);

// Book Absence
export const bookAbsence = createAsyncThunk<
  Absence, // Adjust the response type accordingly
  Absence, // The argument is the new absence object
  {
    state: RootState; // Access to the state
  }
>(
  'absences/bookAbsence',
  async (newAbsence, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    // Use the accessToken when calling the API
    const data = await api.bookAbsenceApi(currentOrganisation, accessToken, newAbsence);
    return data;
  }
);

// Book Absence
export const updateAbsence = createAsyncThunk<
  Absence, // Adjust the response type accordingly
  Absence, // The argument is the new absence object
  {
    state: RootState; // Access to the state
  }
>(
  'absences/updateAbsence',
  async (newAbsence, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    // Use the accessToken when calling the API
    const data = await api.updateAbsenceApi(currentOrganisation, accessToken, newAbsence);
    return data;
  }
);
  
// Fetch Non-Working Days
export const fetchNonWorkingDays = createAsyncThunk<
  NonWorkingDay[], // Adjust the response type accordingly
  string,          // The argument is now a string (employeeId)
  {
    state: RootState; // Access to the state
  }
>(
  'absences/fetchNonWorkingDays',
  async (employeeId, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    // Use the accessToken and teamId when calling the API
    const data = await api.fetchNonWorkingDaysApi(currentOrganisation, accessToken, employeeId);
    return data;
  }
);

// Fetch Absence Types
export const fetchAbsenceTypes = createAsyncThunk<
  HolidayType[], // Adjust the response type accordingly
  string,          // The argument is now a string (employeeId)
  {
    state: RootState; // Access to the state
  }
>(
  'absences/fetchAbsenceTypes',
  async (employeeId, { getState }) => {
    const state = getState();
    const accessToken = state.rootReducer.auth.user?.access_token ?? '';
    const currentOrganisation = state.rootReducer.auth.currentOrganisation;

    // Use the accessToken and teamId when calling the API
    const data = await api.fetchAbsenceTypesApi(currentOrganisation, accessToken, employeeId);
    return data;
  }
);

const absencesSlice = createSlice({
  name: 'absences',
  initialState,
  reducers: {
    resetNeedsRefresh: (state) => {
      state.needsRefresh = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbsences.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAbsences.fulfilled, (state, action: PayloadAction<Absence[]>) => {
        state.absences = action.payload;
        state.status = 'succeeded';
        state.loading = false;
      })
      .addCase(fetchAbsences.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch absences';
      })
      .addCase(bookAbsence.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(bookAbsence.fulfilled, (state, action: PayloadAction<Absence>) => {
        state.absences = [...state.absences, action.payload]; // Create a new array with the new absence
        state.status = 'succeeded';
        state.loading = false;
        state.needsRefresh = true; // Set refresh flag

      })
      .addCase(updateAbsence.fulfilled, (state, action: PayloadAction<Absence>) => {
        state.absences = [...state.absences, action.payload]; // Create a new array with the new absence
        state.status = 'succeeded';
        state.loading = false;
        state.needsRefresh = true; // Set refresh flag
      })
      .addCase(bookAbsence.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to book absence';
      })
      .addCase(fetchNonWorkingDays.fulfilled, (state, action: PayloadAction<NonWorkingDay[]>) => {
        state.nonWorkingDays = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchNonWorkingDays.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch non working days';
      })
      .addCase(fetchNonWorkingDays.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchAbsenceTypes.fulfilled, (state, action: PayloadAction<HolidayType[]>) => {
        state.absenceTypes = action.payload;
        state.status = 'succeeded';
      })
      .addCase(fetchAbsenceTypes.rejected, (state, action) => {
        state.loading = false;
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch absence types';
      })
      .addCase(fetchAbsenceTypes.pending, (state) => {
        state.loading = true;
        state.status = 'loading';
        state.error = null;
      });
      
  },
});

export const { resetNeedsRefresh } = absencesSlice.actions;

export default absencesSlice.reducer;
