import React, { useState, useEffect } from 'react';
import { bookAbsence, updateAbsence } from '../slices/absencesSlice';
import { Absence, HolidayType, NonWorkingDay } from '../types/absenceTypes';
import styles from './AbsenceBookingForm.module.css';
import { formatDateToLocalISO } from '../../../utils/dateUtils';
import { useAppDispatch } from '../../../app/hooks';
import { Employee } from '../types/employeeTypes';

interface AbsenceBookingFormProps {
  absenceId: string | null;
  startDate: Date | null;
  endDate: Date | null;
  nonWorkingDays: NonWorkingDay[];
  absences: Absence[];
  allowance: number;
  allowPurchaseMoreHolidays: boolean;
  onClose: () => void;
  show: boolean; // New prop to manage visibility
  employeeList: Employee[]; 
  absenceTypes: HolidayType[];
}

const AbsenceBookingForm: React.FC<AbsenceBookingFormProps> = ({ absenceId, startDate, endDate, nonWorkingDays, absences, allowance, allowPurchaseMoreHolidays, onClose, show, employeeList, absenceTypes }) => {
  const dispatch = useAppDispatch();
  const [employeeId, setEmployeeId] = useState('');
  const [start, setStart] = useState<string>(startDate ? formatDateToLocalISO(startDate) : '');
  const [end, setEnd] = useState<string>(endDate ? formatDateToLocalISO(endDate) : '');
  const [type, setType] = useState<Absence['type']>(10);
  const [status, setStatus] = useState<Absence['status']>(10);
  const [daysOff, setDaysOff] = useState(0);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (startDate) setStart(formatDateToLocalISO(startDate));
    if (endDate) setEnd(formatDateToLocalISO(endDate));
    if (startDate && endDate) {
      const daysOffCount = calculateWorkingDays(startDate, endDate);
      setDaysOff(daysOffCount);

      if (daysOffCount > allowance) {
        setValidationMessage(`You have requested ${daysOffCount} days, but only have ${allowance} days remaining.`);
      } else {
        setValidationMessage(null);
      }
    }
  }, [startDate, endDate, nonWorkingDays, absences, allowance]);

  useEffect(() => {
    if (employeeList.length === 1) {
      setEmployeeId(employeeList[0].id);
    }
  }, [employeeList]);

  const isNonWorkingOrAbsentDay = (date: Date) => {
    return (
      nonWorkingDays.some(nonWorkingDay => {
        const nonWorkingDate = new Date(nonWorkingDay.date);
        nonWorkingDate.setHours(0, 0, 0, 0);
        const normalizedDate = new Date(date);
        normalizedDate.setHours(0, 0, 0, 0);
        return nonWorkingDate.getTime() === normalizedDate.getTime();
      }) ||
      absences.some(absence => {
        const absenceStart = new Date(absence.startDate);
        const absenceEnd = new Date(absence.endDate);
        absenceStart.setHours(0, 0, 0, 0);
        absenceEnd.setHours(0, 0, 0, 0);
        return date >= absenceStart && date <= absenceEnd;
      })
    );
  };

  const calculateWorkingDays = (start: Date, end: Date) => {
    let count = 0;
    let currentDate = new Date(start);

    while (currentDate <= end) {
      if (!isNonWorkingOrAbsentDay(currentDate)) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  
    // Check if the number of days exceeds the allowance
    if (daysOff > allowance) {
      setValidationMessage(`Cannot book ${daysOff} days as it exceeds your remaining allowance of ${allowance} days.`);
      return;
    }
  
    // Create or update the absence object
    const newAbsence: Absence = {
      id: absenceId || '', // Use existing ID for updates or leave empty for new booking
      employeeId,
      startDate: start,
      endDate: end,
      type,
      status,
    };
  
    if (absenceId) {
      // Update the existing absence
      dispatch(updateAbsence({ ...newAbsence, id: absenceId }));
    } else {
      // Create a new absence
      dispatch(bookAbsence(newAbsence));
    }
  
    // Close the form after submission
    onClose();
  };

  return (
    <div className={`${styles.absenceBookingForm} ${show ? styles.show : ''}`}>
      <form onSubmit={handleSubmit}>
        <h2>Book Absence</h2>
        <p>Your allowance: {allowance} days</p>
        <p>This booking will use: {daysOff} days</p>
        <p>Remaining allowance after booking: {allowance - daysOff >= 0 ? allowance - daysOff : 0} days</p>

        {validationMessage && (
          <p className={styles.validationMessage}>{validationMessage}</p>
        )}

        <label>
          Employee:
          <select 
            value={employeeId} 
            onChange={(e) => setEmployeeId(e.target.value)} 
            required
          >
            <option value="" disabled>Select an employee</option>
            {employeeList.map(employee => (
              <option key={employee.id} value={employee.id}>
                {employee.preferredName}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input type="date" value={start} onChange={(e) => setStart(e.target.value)} required />
        </label>
        <label>
          Last Date:
          <input type="date" value={end} onChange={(e) => setEnd(e.target.value)} required />
        </label>
        <label>
        Type:
          <select value={type} onChange={(e) => setType(parseInt(e.target.value))}>
            {absenceTypes.map((absenceType) => (
              <option key={absenceType.value} value={absenceType.value}>
                {absenceType.name}
              </option>
            ))}
          </select>
        </label>

        <button className='btn btn-success' type="submit" disabled={daysOff > allowance}>Submit</button>
        <button className='btn btn-secondary' type="button" onClick={onClose}>Cancel</button>

        {allowPurchaseMoreHolidays && daysOff > allowance && (
          <button className='btn btn-primary' type="button" onClick={() => alert('Redirect to purchase more holidays')}>
            Buy More Holidays
          </button>
        )}
      </form>
    </div>
  );
};

export default AbsenceBookingForm;