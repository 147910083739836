import { createDefaultEmployeeProfile, EmployeeProfile } from "../types/types";
import { getAppConfig } from '../../../config/configManager'

import axios from "axios";


const API_BASE_URL = getAppConfig().baseUrl;

const createEmployeeProfile = async (organisation: string, token: string, id: string): Promise<EmployeeProfile> => {
  try {
    const response = await axios.post(`${API_BASE_URL}/employee/${id}/profile`, createDefaultEmployeeProfile(id), {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    // Axios automatically parses the response as JSON
    return response.data as EmployeeProfile;
  } catch (error) {
    throw new Error('Failed to create employee profile');
  }
};

const fetchEmployeeProfile = async (organisation: string, token: string, id: string): Promise<EmployeeProfile> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/employee/${id}/profile`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    // Axios automatically parses the response as JSON
    return response.data as EmployeeProfile;
  } catch (error:any) {
    if (axios.isAxiosError(error) && error.response) {
      // Check for ERR:61 in the response
      if (error.status === 409 && error.response.data === 'ERR:61') {
        return createEmployeeProfile(organisation, token, id); // Call createUserProfile if ERR:61
      }
    }
    throw new Error('Failed to fetch user profile');
    }
};

const updateEmployeeProfile = async (organisation: string, token: string, newProfile: EmployeeProfile) => {
  try {
    await axios.put(`${API_BASE_URL}/employee/${newProfile.id}/profile`, newProfile, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
  } catch (error) {
    throw new Error('Failed to update employee profile');
  }
};

export { fetchEmployeeProfile, updateEmployeeProfile };
