import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppDispatch } from '../store/store';
import { useDispatch } from 'react-redux';
import { handleLoginCallback } from '../slices/authSlice';
import { useLoading } from "./LoadingContext";

export const Callback: React.FC = () => {
  const navigate = useNavigate();
  const { setIsLoading, setIsAppInit, setMessage } = useLoading();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(handleLoginCallback());
  }, [dispatch]);

  useEffect(() => {
    // Simulate a loading or login process
    const loginProcess = async () => {
      setMessage("Logging you in, please wait a moment...")
      setIsLoading(true);
      setIsAppInit(false);
      //Whilst the async auth processes are completing show a nice page for 1.75 seconds...
      await new Promise((resolve) => (setTimeout(resolve, 1750)));

      // Redirect to the root of the app after login
      navigate("/");
    };

    // Call the login process function
    loginProcess().finally(async () => {
      await new Promise((resolve) => (setTimeout(resolve, 750)));
      setIsLoading(false);
      // Set loading to false when the login process is complete
    });
  }, [navigate]);

  return (
    <>Loading behind the scenes....</>
  );
};

export default Callback;

