import axios from 'axios';
import { Absence, HolidayType, NonWorkingDay } from '../types/absenceTypes';
import { getAppConfig } from '../../../config/configManager';

const API_BASE_URL = getAppConfig().baseUrl;

// Helper function to check if the response is successful or throw an error
const handleResponse = (response: any) => {
  if (!response || response.status !== 200) {
    throw new Error(`Failed to fetch data. Status: ${response.status}`);
  }
  return response.data;
};

export const fetchAbsencesApi = async (organisation: string, token: string, employeeId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/${employeeId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
    return response.data as Absence[]
  } catch (error) {
    console.error(`Error fetching absences for employee ${employeeId}:`, error);
    throw new Error('Failed to fetch absences');
  }
};

export const bookAbsenceApi = async (organisation: string, token: string, absence: Absence) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/holidays/${absence.employeeId}/book`, absence, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error booking absence for employee ${absence.employeeId}:`, error);
    throw new Error('Failed to book absence');
  }
};

export const updateAbsenceApi = async (organisation: string, token: string, absence: Absence) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/holidays/${absence.employeeId}/book/${absence.id}`, absence, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });
    return handleResponse(response);
  } catch (error) {
    console.error(`Error booking absence for employee ${absence.employeeId}:`, error);
    throw new Error('Failed to book absence');
  }
};

export const fetchNonWorkingDaysApi = async (organisation: string, token: string, employeeId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/${employeeId}/nonworkingdays`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data as NonWorkingDay[]
  } catch (error) {
    console.error(`Error fetching non-working days for employee ${employeeId}:`, error);
    throw new Error('Failed to fetch non-working days');
  }
};


export const fetchAbsenceTypesApi = async (organisation: string, token: string, employeeId: string) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/holidays/${employeeId}/Types`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'X-Current-Organisation' : organisation
      },
    });

    return response.data as HolidayType[]
  } catch (error) {
    console.error(`Error fetching absence types employee ${employeeId}:`, error);
    throw new Error('Failed to fetch absence types');
  }
};

