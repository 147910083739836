import React from 'react';
import ThemeSelectorComponent from '../ThemeSelector/ThemeSelector';
import LanguageSelectorComponent from '../LanguageSelector/LanguageSelector';
import AppVersion from '../../appVersion';

const Footer: React.FC = () => {
    return (
      <footer className="sticky-footer bg-white">
      <div className="container-fluid my-auto">
          <div className="row justify-content-between">
              <div className="col-auto text-left my-auto">
                  <span>&copy; 2024 - 2.1.0</span>
                  <AppVersion />
              </div>
              <div className="col-auto d-flex align-items-center">
                  <div className="mx-2">
                      <ThemeSelectorComponent />
                  </div>
                  <div className="mx-2">
                      <LanguageSelectorComponent />
                  </div>
              </div>
          </div>
      </div>
  </footer>
      );
};

export default Footer;
