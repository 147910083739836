// src/hooks/useAbsenceData.ts
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAbsences, fetchAbsenceTypes, fetchNonWorkingDays, resetNeedsRefresh } from '../slices/absencesSlice';
import { AppDispatch, RootState } from '../../../store/store';

export const useAbsenceData = (employeeId: string) => {
  const dispatch = useDispatch<AppDispatch>();

  const absences = useSelector((state: RootState) => state.absences.absences);
  const nonWorkingDays = useSelector((state: RootState) => state.absences.nonWorkingDays);
  const absenceTypes = useSelector((state: RootState) => state.absences.absenceTypes);
  const loading = useSelector((state: RootState) => state.absences.loading);
  const error = useSelector((state: RootState) => state.absences.error);
  const status = useSelector((state: RootState) => state.absences.status);
  const needsRefresh = useSelector((state: RootState) => state.absences.needsRefresh);

  useEffect(() => {
    if (status === 'failed') {
      throw new Error('Oops, something went wrong!');
    }

    if (status === 'idle' && !loading) {
      // Initial fetch
      dispatch(fetchAbsences(employeeId));
      dispatch(fetchNonWorkingDays(employeeId));
      dispatch(fetchAbsenceTypes(employeeId));

    }
  }, [dispatch, employeeId, status, loading]);

  useEffect(() => {
    if (needsRefresh) {
      dispatch(fetchAbsences(employeeId)); // Refresh holidays
      dispatch(resetNeedsRefresh()); // Reset flag to prevent looping
    }
  }, [dispatch, employeeId, needsRefresh]);

  return { absences, absenceTypes, nonWorkingDays, loading, error };
};
