import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';
import { Employee, initialState } from '../types/employeeTypes';

const useMockApi = process.env.REACT_APP_USE_MOCK_API === 'true';
const api = useMockApi ? require('../api/employeeMockApi') : require('../api/employeeApi');

export const fetchEmployees = createAsyncThunk<Employee[], void, { state: RootState }>(
    'employees/fetchEmployees',
    async (_, { getState }) => {
      const state = getState();
      const accessToken = state.rootReducer.auth.user?.access_token ?? '';
      const currentOrganisation = state.rootReducer.auth.currentOrganisation;

      return await api.fetchEmployeesApi(currentOrganisation, accessToken);
    }
  );

const employeeSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployees.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEmployees.fulfilled, (state, action: PayloadAction<Employee[]>) => {
        state.employees = action.payload;
        state.loading = false;
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch employees';
      });
  },
});

export default employeeSlice.reducer;
