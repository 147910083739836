import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DropdownProps {
  icon: any;
  badgeCount: number;
  open: boolean;
  toggle: () => void;
  items: any[];
  renderItem: (item: any) => JSX.Element;
  noItemsText: string;
  seeMoreText: string;
}

const Dropdown: React.FC<DropdownProps> = ({ icon, badgeCount, open, toggle, items, renderItem, noItemsText, seeMoreText }) => {
  return (
    <li className={`nav-item dropdown no-arrow mx-1 ${open ? 'show' : ''}`}>
      <a className="nav-link dropdown-toggle" href="#" role="button" onClick={toggle} aria-haspopup="true" aria-expanded={open}>
        <FontAwesomeIcon icon={icon} className="fa-fw" />
        <span className="badge badge-danger badge-counter">{badgeCount > 0 ? `${badgeCount}+` : '0'}</span>
      </a>
      <div className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in ${open ? 'show' : ''}`}>
        <h6 className="dropdown-header">{seeMoreText}</h6>
        {items.length > 0 ? items.map(renderItem) : (
          <Link className="dropdown-item text-center small text-gray-500" to="#">
            {noItemsText}
          </Link>
        )}
        {items.length > 3 && (
          <Link className="dropdown-item text-center small text-gray-500" to="#">
            {seeMoreText}
          </Link>
        )}
      </div>
    </li>
  );
};

export default Dropdown;
