
export interface Employee {
    id: string;
    fullName: string;
    preferredName: string;
    professionalSuffix: string;
    department: string;
    team: string;
    jobTitle: string;
    profilePicture: string;

  }
  
  export interface EmployeeState {
    employees: Employee[];
    loading: boolean;
    error: string | null;
  }
  
  export const initialState: EmployeeState = {
    employees: [],
    loading: false,
    error: null,
  };
  