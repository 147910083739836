import version from './version.json';

const AppVersion: React.FC = () => {
    return (
        <div>
            <p>App Version: {version.version}</p>
        </div>
    );
};

export default AppVersion;