import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import Sidebar from './Layouts/Sidebar';
import Footer from './Layouts/Footer';
import Header from './Layouts/Header';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import Callback from '../utils/Callback';
import {SilentRenewComponent as Renew } from '../silentRenewComponent'

import styles from "../utils/AutoLogin.module.css"; // Import the CSS file for styling
import { MenuItem } from './Layouts/types/types';
import { AutoLogin } from '../utils/AutoLogin';
import { UserProfile } from '../features/user/types/types';
import AlertList from '../features/alerts/components/AlertList';

interface LayoutProps {
  routes: MenuItem[];
  userProfile?: UserProfile | null;
}

const Layout: React.FC<LayoutProps> = ({ routes, userProfile: user }) => {
const userPrinciple = useSelector((state: RootState) => state.rootReducer.auth?.user);
const isPinned = useSelector((state: RootState) => state.rootReducer.navbar.isPinned);

  return (
    <>
      {(user) ?
        <div className="app-container">
          <Sidebar routes={routes} isPinned={isPinned}>
              <div style={{width: "100%"}} className={isPinned ? "d-flex flex-column pinned" : "d-flex flex-column"}>
                <div className="content-body">
                  <Header routes={routes} />
                    <div className='container-fluid' style={{paddingTop: "5px"}}>
                      <Routes>
                        {routes.map((route, index) => (
                          route.submenu ? (
                            route.submenu.map((r, i) => (
                              <Route key={i} path={r.route} Component={r.component} />
                            ))
                          ) : (
                            <Route key={index} path={route.route} Component={route.component} />
                          )
                        ))}
                        <Route path='/renew' element={<Renew />} /> 
                        <Route path='/Alerts' element={ <AlertList />} />
                      </Routes>
                    </div>
                </div>
            </div>
          </Sidebar>

          <Footer />
        </div> 
        :
        <div className={styles.overlay}>
            <Routes>
            <Route path="*" element={<AutoLogin user={userPrinciple} />} /> 
            <Route path="/callback" element={<Callback />} /> 
            <Route path="/renew" element={<Renew />} /> 
            </Routes>
        </div>
        }
    </>
  );
};

export default Layout;
