import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../slices/authSlice';
import { MenuItem } from './types/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faFileAlt, faEnvelope, faUser, faCogs, faList, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './Header.module.css';
import { AppDispatch } from '../../store/store';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Common/Modal';
import UserProfile from '../../features/user/components/UserProfile';
import Settings from '../../features/settings/components/Settings';
import ActivityLog from '../../features/activityLog/components/ActivityLog';
import { format } from 'date-fns';
import { useAlertData } from '../../features/alerts/hooks/useAlertData';
import { useMessageData } from '../../features/messages/hooks/useMessageData';
import { useEmployeeData } from '../../features/employee/hooks/useEmployeeData';
import Dropdown from '../Common/Dropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface HeaderProps {
  routes: MenuItem[];
}

const Header: React.FC<HeaderProps> = ({ routes }) => {

  const userPrinciple = useSelector((state: RootState) => state.rootReducer.auth?.user);
  const userId = userPrinciple?.profile.sub;
    const { employeeProfile, status } = useEmployeeData(userId || ''); //TODO this was to stop the conditional checks
    const loggedInEmployeeProfile = employeeProfile;
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();

    const { alerts } = useAlertData();
    const { unreadThreads } = useMessageData();
    const [openDropdown, setOpenDropdown] = useState<string | null>(null);
    const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isActivityLogModalOpen, setIsActivityLogModalOpen] = useState(false);

    useEffect(() => {
      const handleDocumentClick = (event: MouseEvent) => {
        if (!(event.target instanceof Element)) return;
        if (!event.target.closest('.dropdown-toggle') && !event.target.closest('.dropdown-menu')) {
          setOpenDropdown(null);
        }
      };
      document.addEventListener('click', handleDocumentClick);
      return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    const handleMenuItemClick = (menuItem: string) => {
      setOpenDropdown(null);
      if (menuItem === 'profile') setIsProfileModalOpen(true);
      else if (menuItem === 'settings') setIsSettingsModalOpen(true);
      else if (menuItem === 'activity-log') setIsActivityLogModalOpen(true);
    };

    const currentLocation = location.pathname.toLowerCase();
    const isMatchingPath = (path?: string) => path && currentLocation.startsWith(path.toLowerCase());

    const findRoute = (items: MenuItem[]): MenuItem | undefined =>
      items.find(item => isMatchingPath(item.route) || isMatchingPath(item.navPath));

    const route = findRoute(routes.flatMap(x => x.submenu || []).filter((item): item is MenuItem => !!item)) || findRoute(routes);

    if (!route) return null;

    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar static-top shadow">
        <div className="pl-4 d-none d-sm-inline-block" style={{ textWrap: 'nowrap', width: '25em' }}>
          <h1 className="font-semibold text-3xl" style={{ display: 'inline' }}>{route.title}</h1>&nbsp;
          <h3 className="text-sm text-gray-500" style={{ display: 'inline' }}>{route.subtitle}</h3>
        </div>

        <form className={`d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 ${styles.search}`}>
          <div className="input-group">
            <input type="text" className="form-control bg-light border-0 small" placeholder={t('search-for')} aria-label="Search" />
            <div className="input-group-append">
              <button className="btn btn-primary" type="button">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
        </form>

        <ul className="navbar-nav ml-auto" style={{ paddingRight: '25px' }}>
          <Dropdown
            icon={faBell}
            badgeCount={alerts.length}
            open={openDropdown === 'alerts'}
            toggle={() => setOpenDropdown(openDropdown === 'alerts' ? null : 'alerts')}
            items={alerts.slice(0, 3)}
            renderItem={alert => (
              <Link key={alert.id} className="dropdown-item d-flex align-items-center" to="/alerts">
                <div className="mr-3">
                  <div className="icon-circle bg-primary">
                    <FontAwesomeIcon icon={faFileAlt} className="text-white" />
                  </div>
                </div>
                <div>
                  <div className="small text-gray-500">{format(new Date(alert.timestamp), 'MMMM d, yyyy')}</div>
                  <span className="font-weight-bold">{alert.title}</span>
                </div>
              </Link>
            )}
            noItemsText={t('goto-alerts')}
            seeMoreText={t('show-all-alerts')}
          />

          <Dropdown
            icon={faEnvelope}
            badgeCount={Object.keys(unreadThreads).length}
            open={openDropdown === 'messages'}
            toggle={() => setOpenDropdown(openDropdown === 'messages' ? null : 'messages')}
            items={Object.values(unreadThreads).slice(0, 3)}
            renderItem={message => (
              <Link key={message.id} className="dropdown-item d-flex align-items-center" to={`/messages/${message.threadId}`}>
                <div className="dropdown-list-image mr-3">
                  <img className="rounded-circle" src={message.sender.profilePicture || './icon.png'} alt={message.sender.name} />
                  <div className={`status-indicator ${message.sender.isOnline ? 'bg-success' : ''}`}></div>
                </div>
                <div>
                  <div className="text-truncate">{message.content}</div>
                  <div className="small text-gray-500">{message.sender.name} · {format(new Date(message.timestamp), 'p')}</div>
                </div>
              </Link>
            )}
            noItemsText={t('goto-messages')}
            seeMoreText={t('read-more-messages')}
  />

          <li className={`nav-item dropdown no-arrow ${openDropdown === 'user' ? 'show' : ''}`}>
                  <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  onClick={() => setOpenDropdown(openDropdown === 'user' ? null : 'user')}
                  aria-haspopup="true"
                >
                {status === 'succeeded' && loggedInEmployeeProfile && (
                <>
                  <span className="mr-2 d-none d-lg-inline text-gray-600 small">
                    {loggedInEmployeeProfile.preferredName || loggedInEmployeeProfile.fullName}
                  </span>
                  <img className="img-profile rounded-circle" src={loggedInEmployeeProfile.profilePicture || './profile.png'} alt="User Profile" />
                </>
              )}
            </a>
            <div className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${openDropdown === 'user' ? 'show' : ''}`} aria-labelledby="userDropdown">
              {['profile', 'settings', 'activity-log'].map(item => (
                <a key={item} className="dropdown-item" href="#" onClick={() => handleMenuItemClick(item)}>
                  <FontAwesomeIcon icon={item === 'profile' ? faUser : item === 'settings' ? faCogs : faList} className="fa-sm fa-fw mr-2 text-gray-400" />
                  {t(item)}
                </a>
              ))}
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={() => dispatch(logout())}>
                <FontAwesomeIcon icon={faSignOutAlt} className="fa-sm fa-fw mr-2 text-gray-400" />
                {t('logout')}
              </a>
            </div>
          </li>
        </ul>

        {isProfileModalOpen && <Modal onClose={() => setIsProfileModalOpen(false)} title={t('userProfile')} modalWidth="860px" modalHeight="770px"><UserProfile /></Modal>}
        {isSettingsModalOpen && <Modal onClose={() => setIsSettingsModalOpen(false)} title={t('settings')} modalWidth="860px" modalHeight="770px"><Settings /></Modal>}
        {isActivityLogModalOpen && <Modal onClose={() => setIsActivityLogModalOpen(false)} title={t('activityLog')} modalWidth="860px" modalHeight="770px"><ActivityLog /></Modal>}
      </nav>
    );
};

export default Header;
