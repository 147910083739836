import React, { useState, useEffect } from 'react';
import styles from './Carousel.module.css';

interface CarouselProps {
  items: React.ReactNode[];
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  disableNavigation?: boolean; // New prop to control navigation
}

const Carousel: React.FC<CarouselProps> = ({ items, currentIndex, setCurrentIndex, disableNavigation = false }) => {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (disableNavigation) return; // Disable key navigation if navigation is disabled
      if (e.key === 'ArrowRight') {
        nextItem();
      } else if (e.key === 'ArrowLeft') {
        prevItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentIndex, items?.length, disableNavigation]);

  const nextItem = () => {
    if (disableNavigation) return;
    setCurrentIndex((currentIndex + 1) % items.length);
  };

  const prevItem = () => {
    if (disableNavigation) return;
    setCurrentIndex((currentIndex - 1 + items.length) % items.length);
  };

  return (
    <div className={styles.carouselContainer}>
      <div className={styles.carousel}>
        <div className={styles.carouselInner} style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {items?.map((item, index) => (
            <div key={index} className={styles.carouselItem}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.carouselControls}>
        <button
          className={styles.carouselButton}
          onClick={prevItem}
          disabled={disableNavigation || items.length <= 1} // Disable button if navigation is disabled or only one item exists
        >
          &lt;
        </button>
        <button
          className={styles.carouselButton}
          onClick={nextItem}
          disabled={disableNavigation || items.length <= 1} // Disable button if navigation is disabled or only one item exists
        >
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Carousel;
